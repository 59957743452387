export function canUseAssistant(): boolean {
  const userId = localStorage.getItem("userId");
  if (userId) {
    return true;
  } else {
    let assistantLimit = localStorage.getItem("assistantLimit");
    if (!assistantLimit) {
      localStorage.setItem("assistantLimit", "0");
      assistantLimit = "0";
    }

    const limit = parseInt(assistantLimit, 10);
    if (limit < 2) {
      localStorage.setItem("assistantLimit", (limit + 1).toString());
      return true;
    } else {
      console.log(
        "Usage limit reached. Please sign in to continue using the assistant."
      );
      return false;
    }
  }
}

export function getCreditsLeft(): number {
  const assistantLimit = localStorage.getItem("assistantLimit");
  const limit = assistantLimit ? parseInt(assistantLimit, 10) : 0;
  return limit;
}