import React, { useEffect, useRef, useState } from 'react';
import Chat, { ChatMethods } from '../components/layout/terminal-layout/assistant/chat';

const Assistant = () => {
  const [input, setInput] = useState<string>("");

 const chatRef = useRef<ChatMethods>(null);


const handleAction = (e: React.KeyboardEvent | React.MouseEvent) => {
  if (input.length > 1) {
 if (
   e.type === "keydown" &&
   (e as React.KeyboardEvent).key === "Enter" &&
   chatRef.current
 ) {
   console.log("Enter pressed");
   chatRef.current.childFunction();
 }

 if (e.type === "click" && chatRef.current) {
   console.log("Element clicked");
   chatRef.current.childFunction();
 }
  }
 
};

 const containerRef = useRef<HTMLDivElement>(null);

    return (
      <div className="h-[540px]  w-full relative justify-center items-center flex flex-col ">
        <div
          ref={containerRef}
          className="w-full h-[460px] overflow-y-scroll overflow-x-hidden white-scrollbar pl-1 pr-2 mb-[70px] scroll-smooth"
        >
          <Chat
            input={input}
            setInput={setInput}
            ref={chatRef}
            containerRef={containerRef}
          />
        </div>

        <div className="flex absolute bottom-[35px] w-[90%] left-1/2 -translate-x-1/2">
          <div className=" flex flex-row justify-between items-center w-full h-12 bg-neutral-700 rounded-[200px] px-[12px]">
            <input
              id="custom-input"
              className="bg-transparent w-full outline-none text-white ml-3"
              type="text"
              // disabled={isWaiting}
              placeholder="Send a message"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleAction}
            />
            <div className="flex flex-row">
              {/* <img
                src="/assets/images/mic.png"
                className="w-[15px] h-5 mr-[12px]"
              /> */}
              <img
                src="/assets/images/carbon_send_icon.png "
                className="w-[24px] h-[24px] cursor-pointer transition-all duration-500 hover:scale-110"
                onClick={handleAction}
              />
            </div>
          </div>
        </div>
        <p className='hidden xs:flex mb-2 text-gray-400'>Please be patient with me, Skynet is currently overloaded</p>
      </div>
    );
};

export default Assistant;