import React, { useEffect, useState } from "react";
import { useComments } from "../../../api/hooks";
import {
  addComment,
  addSubComment,
  fetchCommentsCount,
} from "../../../api/comments";
import Comment from "./comment";
import { IoClose } from "react-icons/io5";

const CommentsSection = ({ slug }: { slug: string }) => {
  const {
    data: comments,
    refetch,
  } = useComments({ slug });

  const [commentsCount, setCommentsCount] = useState<number>(0);

  const [replyingTo, setReplyingTo] = useState<string | null>(null);

  const [userInput, setUserInput] = useState<string>("");

  useEffect(() => {
    const fetchCommentsAmount = async () => {
      try {
        const data = await fetchCommentsCount(slug);
        setCommentsCount(data.count);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCommentsAmount();
  }, [slug, comments]);

  const sendHandler = () => {
    if (replyingTo) {
      addSubComment(replyingTo, userInput).then(() => refetch());
    } else {
      addComment(slug, userInput).then(() => refetch());
    }
    setUserInput("");
    setReplyingTo(null);
   
  };

  return (
    <div className="flex flex-col mt-[50px] xs:mt-[150px] max-w-[730px] w-full justify-start px-[15px] xs:px-0">
      <h1 className="text-white font-bold text-[30px]">
        {commentsCount} Comments
      </h1>
      <div className="flex flex-col items-start justify-center relative my-4 text-customTextGrey">
        {replyingTo && (
          <div className="flex flex-row gap-x-[2px]">
            <p className="text-sm mr-1">replying to:</p>"
            <p className="text-sm text-customRed">
              {comments &&
                comments.find((obj) => obj.id === replyingTo)?.content}
            </p>
            "
            <IoClose
              className="width-[12px] mt-[2px] ml-[4px] cursor-pointer"
              onClick={() => setReplyingTo(null)}
            />
          </div>
        )}
        <textarea
          placeholder={replyingTo ? "Reply to a comment" : "Write a comment"}
          rows={4}
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          className="bg-customArticleGrey rounded-lg border border-[#444549] w-full h-[120px] p-4 my-1 focus:outline-none focus:border-customRed"
        />

        <div
          onClick={sendHandler}
          className="cursor-pointer absolute bottom-4 right-3 bg-customRed text-white font-bold rounded-lg px-2 py-1"
        >
          Send
        </div>
      </div>

      <div className="flex flex-col">
        {comments?.map((comment, index) => (
          <div key={index}>
            <Comment
              id={comment.id}
              authorId={comment.author?.id}
              authorName={comment.author?.username}
              profilePicture={comment.author?.profilePicture}
              content={comment.content}
              createdAt={comment.createdAt}
              subcomments={comment.subcomments}
              setReplyingTo={setReplyingTo}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommentsSection;
