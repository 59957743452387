import axios from "axios";
import React, { useEffect } from "react";
import { useAuth } from "../components/layout/authContext";
import { useNavigate } from "react-router-dom";

const Redirect = () => {
  const navigate = useNavigate();
  const { setAuthToken, setUserId } = useAuth();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("access_token");
    if (code) {
      axios
        .get(
          `${process.env.REACT_APP_STRAPI_URL}/api/auth/google/callback?access_token=${code}`
        )
        .then((response) => {
          console.log("Authentication success! " + JSON.stringify(response.data));
          if (response.data.jwt) {
            setAuthToken(response.data.jwt);
            setUserId(response.data.user.id);
            navigate("/");
          }
        })
        .catch((error) => {
          console.error("Authentication failed!", error);
        });
    }
  }, []);

  return <h1>Authenticating...</h1>;
};

export default Redirect;
