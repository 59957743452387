import React, { useEffect, useState } from "react";
import SideNav from "./sideNav";
import { useLocation } from "react-router-dom";
import Partnerships from "./partnerships";
import TopAds from "./top-ads";
import TopAdsMobile from "./top-ads-mobile";
import Partners from "./partners";

interface TerminalLayoutProps {
  children: React.ReactNode;
}

const TerminalLayout = ({ children }: TerminalLayoutProps) => {
  const [selectedTab, setSelectedTab] = useState("Research");
  const location = useLocation();

  useEffect(() => {
    const determineSelectedTab = (pathname: string) => {
      if (pathname.includes("/newsletter")) {
        return "Newsletters";
      }
      if (pathname.includes("/ecosystems")) {
        return "Research";
      }

      if (pathname.includes("/assistant")) {
        return "AI Assistant";
      }
      if (pathname.includes("/market-reports")) {
        return "Market Reports";
      }

      return "Research";
    };

    setSelectedTab(determineSelectedTab(location.pathname));
  }, [location]);

  return (
    <div>
      <TopAds />
      <TopAdsMobile />
      <section className="flex h-full max-w-screen overflow-x-hidden flex-row w-full pt-[15px] xs:pt-[20px] px-[15px] xs:px-[24px] gap-4 mb-[100px]">
        <SideNav selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        <div className="w-full h-full min-h-[540px] bg-customGrey rounded-lg ">
          {children}
        </div>
      </section>
      <Partners />
    </div>
  );
};

export default TerminalLayout;
