import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { IMessage } from "../../../../types/types";
import { initializeAssistants } from "./assistants";
import {
  deleteEntireConversation,
  getConversationByUserId,
  getNewTokens,
  getOhlc,
  getTokenData,
  getTrendingTokens,
  sendMessageToServer,
} from "../../../../api/assistant";
import Message from "./message";
import { useAuth } from "../../authContext";
import { fetchUserImage } from "../../../../api/user";
import { exportPDF } from "../../../../helpers/exportPDF";
import { PiExportBold } from "react-icons/pi";
import { MdClose } from "react-icons/md";
import {
  canUseAssistant,
  getCreditsLeft,
} from "../../../../helpers/canUseAssistant";
import SuggestionButton from "./suggestion-button";

interface ChatProps {
  input: string;
  setInput: (arg: string) => void;
  containerRef: React.RefObject<HTMLDivElement>;
}

export interface ChatMethods {
  childFunction: () => void;
}

const Chat = forwardRef<ChatMethods, ChatProps>(
  ({ input, setInput, containerRef }, ref) => {
    const [creditsLeft, setCreditsLeft] = useState<number>(2);
    const [chatHeight, setChatHeight] = useState<number>(0);

    const baseMessages = [
      new IMessage(
        false,
        2 - getCreditsLeft() === 0 && !canUseAssistant()
          ? "Sorry, you have used all your assistant credits. To continue the conversation, please [create a free account](https://m6labs.co/login)."
          : "Greetings, I'm Max! Please provide a token's symbol or name to return market data, ask me a question, or try one of the example prompts below. Feel free to customize your prompts!",
        true
      ),
    ];
    const [isWaiting, setIsWaiting] = useState<boolean>(false);
    const [messages, setMessages] = useState<Array<IMessage>>(baseMessages);

    const [researcherAssistant, setResearcherAssistant] = useState<any>(null);
    const [thread, setThread] = useState<any>(null);
    const [openai, setOpenai] = useState<any>(null);

    useEffect(() => {
      setCreditsLeft(2 - getCreditsLeft());
    }, []);

    useImperativeHandle(ref, () => ({
      childFunction() {
        if (!isWaiting) {
          handleSendMessage(input);
        } else {
          alert("Please wait for the current message to finish processing.");
        }
      },
    }));

    useEffect(() => {
      initChatBot();
         
    }, []);

    useEffect(() => {}, [researcherAssistant]);

    const initChatBot = async () => {
      // Create an assistants and thread
      const { researcherAssistant, thread, openai } =
        await initializeAssistants();

      setOpenai(openai);
      setResearcherAssistant(researcherAssistant);
      setThread(thread);
    };

    const createNewMessage = (
      content: string,
      isUser: boolean,
      shouldAnimate: boolean
    ) => {
      const newMessage = new IMessage(isUser, content, shouldAnimate);
      return newMessage;
    };

    const handleSendMessage = async (input: string) => {
      addMessage(input, true);
      setInput("");
      setIsWaiting(true);

      if (!canUseAssistant()) {
        addMessage(
          "Sorry, you have used all your assistant credits. Please [login](https://m6labs.co/login) to continue using the assistant.",
          false
        );
        setIsWaiting(false);
        return;
      }

      setCreditsLeft(creditsLeft - 1);

      await sendUserMessage(input);
      let response = await runAssistantAndWait(researcherAssistant.id);

      if (response.status === "completed") {
        console.log("completed");
        await processAssistantResponse(response.id);
      } else {
        addMessage("Sorry, I haven't found anything", false);
      }

      setIsWaiting(false);
    };

    const sendUserMessage = async (messageContent: string) => {
      console.log("Sending message");
      await openai.beta.threads.messages.create(thread.id, {
        role: "user",
        content: messageContent,
      });
    };

    const runAssistantAndWait = async (
      assistantId: string,
      additionalMessage = ""
    ) => {
      console.log("Run and wait");
      if (additionalMessage) {
        await sendUserMessage(additionalMessage);
      }
      const run = await openai.beta.threads.runs.create(thread.id, {
        assistant_id: assistantId,
      });
      return await waitForResponse(run.id);
    };

    const waitForResponse = async (runId: string) => {
      console.log("Wait for response");
      let response = await openai.beta.threads.runs.retrieve(thread.id, runId);
      while (
        response.status === "in_progress" ||
        response.status === "queued" ||
        response.status === "requires_action"
      ) {
        if (
          response.status === "requires_action" &&
          response.required_action.type === "submit_tool_outputs"
        ) {
          await handleToolOutputs(response);
        }
        await new Promise((resolve) => setTimeout(resolve, 3000));
        response = await openai.beta.threads.runs.retrieve(thread.id, runId);
      }
      return response;
    };

    const handleToolOutputs = async (response: any) => {
      console.log("Handle output!");
      const toolOutputs = await Promise.all(
        response.required_action.submit_tool_outputs.tool_calls.map(
          async (call: any) => {
            if (call.function.name === "getTokenData") {
              const args = JSON.parse(call.function.arguments);
              const tokenData = await getTokenData(args.query);
              return {
                tool_call_id: call.id,
                output: JSON.stringify(tokenData),
              };
            }
            if (call.function.name === "getNewTokens") {
              const newTokens = await getNewTokens()
              return {
                tool_call_id: call.id,
                output: JSON.stringify(newTokens),
              };
            }
             if (call.function.name === "getTrendingTokens") {
               const newTokens = await getTrendingTokens();
               return {
                 tool_call_id: call.id,
                 output: JSON.stringify(newTokens),
               };
             }
            if (call.function.name === "getOhlc") {
              const args = JSON.parse(call.function.arguments);
              const tokenData = await getOhlc(args.query, args.days);
              return {
                tool_call_id: call.id,
                output: JSON.stringify(tokenData),
              };
            }
          }
        )
      );
      await openai.beta.threads.runs.submitToolOutputs(thread.id, response.id, {
        tool_outputs: toolOutputs,
      });
    };

    const processAssistantResponse = async (runId: string) => {
      const lastMessage = await getLastAssistantMessage(runId);
      console.log("LAST MESSAGE IS " + JSON.stringify(lastMessage))


      if (lastMessage) {
        addMessage(
          lastMessage.content[0]["text"].value,
          false
        );
      }
    };

    const getLastAssistantMessage = async (runId: string) => {
      const messageList = await openai.beta.threads.messages.list(thread.id);
      const mdata = messageList.data
        .filter(
          (message: any) =>
            message.run_id === runId && message.role === "assistant"
        )
        .pop();
      return mdata;
    };

    const addMessage = (content: string, isUser: boolean) => {
      console.log("Add message");
      setMessages((prevMessages) => [
        ...prevMessages,
        createNewMessage(content, isUser, true),
      ]);
      sendMessageToServer(content, isUser, userId);
    };

    const [profilePicture, setProfilePicture] = useState<string>(
      "/assets/images/user.png"
    );
    const { userId } = useAuth();

    useEffect(() => {
      if (userId) {
        getConversationByUserId(userId).then((res: any) => {
          if (res.data.length > 0) {
            const final = res.data.map((item: any) => ({
              isUser: item.attributes.isUser,
              content: item.attributes.content,
            }));
            const newMessages = final.map((item: any) =>
              createNewMessage(item.content, item.isUser, false)
            );

            setMessages(newMessages);
            console.log(final);
          } else {
            console.log("No data returned from the API");
          }
        });
        fetchUserImage(userId).then((res) => {
          if (res.profilePicture) {
            setProfilePicture(`${res.profilePicture.url}`);
          }
        });
      }
    }, [userId]);

    const handleDownloadClick = async () => {
      await exportPDF(messages);
    };

    const handleDeleteClick = async () => {
      if (userId) {
        await deleteEntireConversation(userId);
        setMessages(baseMessages);
        localStorage.removeItem("threadId");
      }
    };

    useEffect(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }, [chatHeight, containerRef]);

    const observedElementRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      const observedElement = observedElementRef.current;
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const { height } = entry.contentRect;
          setChatHeight(height);
        }
      });

      if (observedElement) {
        resizeObserver.observe(observedElement);
      }

      return () => {
        if (observedElement) {
          resizeObserver.unobserve(observedElement);
        }
      };
    }, []);


    async function indexImage() {
      const fileid = "file-Ys4iTbiE1Cx4gkQVGuVC0xy2";
      console.log("Loading ", fileid);

      const file = await openai.files.content(fileid);

      console.log(file.headers);

      const bufferView = new Uint8Array(await file.arrayBuffer());

      //@ts-ignore
       const base64Image = btoa(String.fromCharCode(...bufferView));

       // Create a data URL for the image
       const imageSrc = `data:image/jpeg;base64,${base64Image}`;

      console.log("Buffer view is " + bufferView);

      return imageSrc; 
    }

    return (
      <>
        <div className="w-full flex flex-col relative" ref={observedElementRef}>
          <div className="flex flex-row justify-end w-full sticky top-[5px]">
            {!userId && (
              <div className="flex flex-row gap-x-1 mr-3">
                <p className="text-customRed">{creditsLeft}</p>
                <p className="text-gray-200">/ 2</p>
              </div>
            )}
            {
              // <FaQuestionCircle className="cursor-pointer text-[22px] text-gray-200 hover:text-customRed  mr-3" />
            }
            {messages.length > 1 && (
              <>
                <PiExportBold
                  onClick={() => handleDownloadClick()}
                  className="cursor-pointer text-[22px] text-gray-200 hover:text-customRed "
                />
                {userId && (
                  <MdClose
                    onClick={() => handleDeleteClick()}
                    className="ml-3 cursor-pointer text-[22px] text-gray-200 hover:text-customRed "
                  />
                )}
              </>
            )}
          </div>

          {messages.map((message, index) => (
            <div
              className={`flex max-w-full ${
                message.isUser ? "justify-end" : "justify-start"
              }`}
              key={index}
            >
              <Message key={index} message={message} picture={profilePicture} />
            </div>
          ))}
          {isWaiting && (
            <div className="flex justify-start">
              <Message message={new IMessage(false, "", true)} isWaiting />
            </div>
          )}
          <div className="min-h-[50px]" />
        </div>
        <div className="absolute w-full px-4 flex flex-col gap-y-3 xs:flex-row hs:gap-y-0 items-center justify-center bottom-[100px] gap-x-5">
          {!isWaiting &&
            (messages.length > 1 ? (
              <>
                <SuggestionButton
                  handler={handleSendMessage}
                  text="Analyze your last message for valuable insights"
                />

                <SuggestionButton
                  handler={handleSendMessage}
                  text="What other research should I do?"
                />
              </>
            ) : (
              <>
                <SuggestionButton
                  handler={handleSendMessage}
                  text="What can you do?"
                />

                <SuggestionButton
                  handler={handleSendMessage}
                  text="Give me a list of new tokens"
                />

                <SuggestionButton
                  handler={handleSendMessage}
                  text="List the current trending tokens"
                  isMobileHidden
                />

                <SuggestionButton
                  handler={handleSendMessage}
                  text="Give me the key market stats for Solana"
                  isMobileHidden
                />
              </>
            ))}
        </div>
      </>
    );
  }
);

export default Chat;
