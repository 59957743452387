import { ISubcomment } from '../../../types/types';
import { BiComment } from "react-icons/bi";

interface CommentProps {
  id?: string;
  setReplyingTo?: (id: string) => void;
  authorId?: string;
  authorName?: string;
  profilePicture?: string;
  content?: string;
  createdAt?: string;
  subcomments?: ISubcomment[];
}

const Comment = ({authorId, content, createdAt, subcomments, authorName, profilePicture, setReplyingTo, id}: CommentProps) => {

    return (
      <div>
        <div className="flex flex-row mt-5">
          <img
            src={`${profilePicture}`}
            className="w-[40px] h-[40px] mr-3 rounded-full bg-white"
            alt="profile"
          />

          <div className="flex flex-col gap-y-[6px]">
            <div className="flex gap-x-2 items-center">
              <p className="text-white font-bold">{authorName}</p>
              <p className="text-customTextGrey text-sm">
                {" "}
                {new Date(createdAt ? createdAt : "").toLocaleDateString(
                  "en-GB",
                  {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  }
                )}
              </p>
            </div>
            <p className="text-white">{content}</p>

            {(subcomments) && (
              <div
                className="flex flex-row items-center text-customTextGrey cursor-pointer"
                onClick={() => {
                  if (id && setReplyingTo) {
                    setReplyingTo(id);
                  }
                }}
              >
                <BiComment className="mr-2 w-[13px]" />
                <p className="text-sm">Comment ({subcomments?.length})</p>
              </div>
            )}
          </div>
        </div>
        <div className="ml-10">
          {subcomments &&
            subcomments.map((subcomment, index) => (
              <Comment
                key={index}
                authorId={subcomment.author?.id}
                authorName={subcomment.author?.username}
                profilePicture={subcomment.author?.profilePicture}
                content={subcomment.content}
                createdAt={subcomment.createdAt}
              
              />
            ))}
        </div>
      </div>
    );
};

export default Comment;