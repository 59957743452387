import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const MobileSwitch = () => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
      <div
        className={`xs:hidden p-2 mb-2 grid grid-cols-2 w-full gap-x-6 gap-y-4 ${
          location.pathname === "/market-reports" && "hidden"
        }`}
      >
        <div
          onClick={() => navigate("/")}
          className={`p-2 max-h-[45px] flex items-center justify-center rounded-full ${
            location.pathname === "/"
              ? "bg-customRed text-white font-bold"
              : "border border-customRed text-customRed "
          }`}
        >
          Categories
        </div>

        <div
          onClick={() => navigate("/ecosystems")}
          className={`p-2 max-h-[45px] flex items-center justify-center rounded-full ${
            location.pathname === "/ecosystems"
              ? "bg-customRed text-white font-bold"
              : "border border-customRed text-white"
          }`}
        >
          Ecosystems
        </div>
      </div>
    );
};

export default MobileSwitch;