import axios from "axios";
import React, { createContext, useState, useEffect, useContext } from "react";
import { deleteUser } from "../../api/user";

interface AuthContextType {
  authToken: string | null;
  setAuthToken: (token: string) => void;
  userId: string | null;
  setUserId: (token: string) => void;
  handleLogout: () => void;
  handleDeleteAccount: () => void;
  isLoading: boolean
}

export const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    const user = localStorage.getItem("userId");
    if (token) {
      setAuthToken(token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    if (user) {
      setUserId(user);
    }
    setIsLoading(false)
  }, [userId]);

  const handleSetAuthToken = (token: string) => {
    localStorage.setItem("jwtToken", token);
    setAuthToken(token);
  };

  const handleSetUserId = (id: string) => {
    localStorage.setItem("userId", id);
    setUserId(id);
  };

  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    setAuthToken(null);
    localStorage.removeItem("userId");
    setUserId(null);
    axios.defaults.headers.common["Authorization"] = null;
  };

  const handleDeleteAccount = () => {
    if (userId) {
      deleteUser(userId).then(() => {
        localStorage.removeItem("jwtToken");
        setAuthToken(null);
        localStorage.removeItem("userId");
        setUserId(null);
        axios.defaults.headers.common["Authorization"] = null;
      });
    }
  };
 
  return (
    <AuthContext.Provider
      value={{
        authToken,
        setAuthToken: handleSetAuthToken,
        userId,
        setUserId: handleSetUserId,
        handleLogout: handleLogout,
        handleDeleteAccount: handleDeleteAccount,
        isLoading: isLoading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
