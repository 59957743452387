import { UseQueryResult, useInfiniteQuery, useQuery } from "react-query";
import { countArticles, fetchArticles, searchArticles } from "./articles";
import { ArticleProps, IComment, fetchedArticleProps } from "../types/types";
import { fetchComments } from "./comments";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
 

export const useFetchArticles = ({
   categories,
   author,
   tags,
   types,
   page,
   pageSize,
 }: ArticleProps) => {
   return useQuery(
     ["articles", { categories, author, tags, types, page, pageSize }],
     () => fetchArticles({ categories, author, tags, types, page, pageSize })
   );
 };

export const useSearchArticles = ({
  searchQuery,
  page,
  pageSize,
  type,
}: {
  searchQuery: string;
  page?: number;
  pageSize?: number;
  type?: "Research" | "News" | "Ecosystems" | "Reports";
}) => {
  return useQuery(
    ["search", { searchQuery, page, pageSize, type }],
    () => searchArticles(searchQuery, page, pageSize, type),
    {
      enabled: searchQuery.length > 0,
    }
  );
};

export const useComments = ({
  slug,
}: {
  slug: string;
}): UseQueryResult<IComment[], Error> => {
  return useQuery<IComment[], Error>(
    ["comments", slug],
    () => fetchComments(slug),
    {
      enabled: !!slug,
    }
  );
};

export const useArticles = (searchInput: string, activeCategory: string, currentPage: number, type: "Ecosystems" | "Research" ) => {
  const [articlesCount, setArticlesCount] = useState(1);
  const [researchArticlesToRender, setResearchArticlesToRender] = useState<
    fetchedArticleProps[]
  >([]);

  const {
    data: researchArticles,
    isLoading: isResearchLoading,
    refetch: researchRefetch,
  } = useFetchArticles({
    types: type,
    ...(activeCategory !== "All" && { categories: activeCategory }),
    page: currentPage,
    pageSize: 10,
  });

  const {
    data: searchArticles,
    isLoading: isSearchLoading,
    refetch: searchRefetch,
  } = useSearchArticles({
    searchQuery: searchInput,
    type: type,
    page: 1,
    pageSize: 50,
  });

  useEffect(() => {
    if (searchInput && searchInput.length > 0) {
      searchRefetch();
    }
  }, [searchInput, searchRefetch]);

  useEffect(() => {
    countArticles({
      types: "Ecosystems",
      ...(activeCategory !== "All" && { categories: activeCategory }),
    }).then((res) => setArticlesCount(res.count));
  }, [researchArticles, activeCategory]);

  useEffect(() => {
    if (researchArticles) {
      setResearchArticlesToRender((prev) => [...prev, ...researchArticles]);
    }
  }, [researchArticles]);

  return {
    researchArticlesToRender,
    searchArticles,
    isResearchLoading,
    isSearchLoading,
    articlesCount,
    researchRefetch,
  };
};

export const useInfiniteScroll = (callback: () => void, hasMore: boolean) => {
  const observerRef = useRef<HTMLDivElement>(null);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting && hasMore && !loadingMore) {
        callback()
        
      }
    });

    const currentObserverRef = observerRef.current;
    if (currentObserverRef) {
      observer.observe(currentObserverRef);
    }

    return () => {
      if (currentObserverRef) {
        observer.unobserve(currentObserverRef);
      }
    };
  }, [hasMore, loadingMore, callback]);

  return { observerRef, setLoadingMore, loadingMore };
};











export const useArticlesInfiniteQuery = (filterParams: ArticleProps) => {
  return useInfiniteQuery(
    ["articles", filterParams],
    ({ pageParam = 1 }) => fetchArticles({ ...filterParams, page: pageParam }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (!filterParams.pageSize || lastPage.length < filterParams.pageSize) {
          return undefined; 
        }
        return allPages.length + 1;
      },
    }
  );
};

export const useIntersectionObserver = (hasNextPage: boolean | undefined, fetchNextPage: () => void) => {
  const { ref, inView } = useInView({ threshold: 0 });

  useEffect(() => {
    console.log("inView", inView);
    if (inView && hasNextPage) {
      console.log("fetchNextPage");
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  return ref;
};

export const useSearchArticlesInfiniteQuery = (
  searchQuery: string,
  pageSize = 10,
  type: "News" | "Research" | "Ecosystems" | "Reports"
) => {
  return useInfiniteQuery(
    ["searchArticles", searchQuery, type],
    ({ pageParam = 1 }) =>
      searchArticles(searchQuery, pageParam, pageSize, type),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length === 0 || lastPage.length < pageSize) {
          return undefined;
        }
        return allPages.length + 1;
      },
      enabled: !!searchQuery,
    }
  );
};