import axios, { AxiosResponse } from "axios";

export const fetchUserData = async (userId: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_STRAPI_URL}/api/users/${userId}?populate=bookmarked&populate=profilePicture`
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const fetchUserImage = async (userId: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_STRAPI_URL}/api/users/${userId}?populate=profilePicture`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error);
  }
};


interface UploadResponse {
  id: string
}

export const uploadFile = async (
  file: File
): Promise<AxiosResponse<UploadResponse[]>> => {
  const formData = new FormData();
  formData.append("files", file);
  return axios.post<UploadResponse[]>(
    `${process.env.REACT_APP_STRAPI_URL}/api/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const updateUserProfile = async (
  fileId: string,
  userId: string
): Promise<AxiosResponse> => {
  return axios.put(`${process.env.REACT_APP_STRAPI_URL}/api/users/${userId}`, {
    profilePicture: fileId,
  });
};

export const updateUserName = async (
  username: string,
  userId: string
): Promise<AxiosResponse> => {
  return axios.put(`${process.env.REACT_APP_STRAPI_URL}/api/users/${userId}`, {
    username: username,
  });
};

export const deleteUser = async (userId: string) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_STRAPI_URL}/api/users/${userId}`
    );

    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error);
  }
};

export const handleForgotPassword = async (email: string) => {
  console.log("CALLED")
  const response = await axios.post(
    `${process.env.REACT_APP_STRAPI_URL}/api/auth/forgot-password`,
    {
      email: email,
    }
  );

  console.log("Forgot password response:", response.data);
  return response.data; 
};

export const handleResetPassword = async (data: {
  code: string;
  password: string;
  passwordConfirmation: string;
}) => {
  const response = await axios.post(
    `${process.env.REACT_APP_STRAPI_URL}/api/auth/reset-password`,
    data
  );
  return response.data;
};