import react from "react";
import { trimAndAddEllipsis } from "../../../../helpers/trimAndAddEllipis";
import { useNavigate } from "react-router";
import React from "react";
import { isNewsletter } from "../../../../helpers/addContentType";

type NewsletterTileProps = {
  title: string;
  subTitle: string;
  date: string;
  img: string;
  slug: string;
  index: number;
  isFeatured?: boolean;
};
const NewsletterTile = React.memo((props: NewsletterTileProps) => {
  const nav = useNavigate();

  const isPostNewsletter = isNewsletter(props.title) || isNewsletter(props.subTitle);

  const isPostReport =
    (isNewsletter(props.title) || isNewsletter(props.subTitle)) &&
    new Date(props.date) > new Date("2022-10-29");


  const imgUrl = `${props.img}`;
  return (
    <div
      className={`w-full animate-fade-in transition-transform duration-500 hover:scale-105 cursor-pointer h-[280px] py-2 px-3 justify-end rounded-lg flex flex-col bg-cover bg-center bg-no-repeat`}
      style={{
        backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.4) 50%), url(${imgUrl})`,
      }}
      onClick={() => nav(`/p/${props.slug}`)}
    >
      <div></div>
      <p className="text-customRed text-[13px]">
        {
          isPostNewsletter ? isPostReport ? "Market Report" : "Newsletter" : "Research"
        }
      </p>
      <span className="text-customTextLight text-[18px] font-bold leading-1">
        {trimAndAddEllipsis(props.title, 45)}
      </span>

      <p className=" text-customTextGrey">
        {new Date(props.date).toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
        })}
      </p>
    </div>
  );
})

export default NewsletterTile;
