import React, { Dispatch, ReactNode, SetStateAction, createContext, useState } from "react";
import Header from "./header";
import Footer from "./footer/footer";
import SubscribeModal from "../shared/subscribeModal";

interface LayoutProps {
  children: ReactNode;
}

 interface ModalContextProps {
   setShowModal: Dispatch<SetStateAction<boolean>>;
 }

 export const ModalContext = createContext<ModalContextProps>({
   setShowModal: () => {}, 
 });

const Layout = ({ children }: LayoutProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <ModalContext.Provider value={{ setShowModal }}>
      <div className="bg-customDark w-full max-w-screen overflow-x-hidden min-h-screen text-white">
        <Header setShowModal={setShowModal} />
        <SubscribeModal show={showModal} setShow={setShowModal} />
        <div className="">{children}</div>
        <Footer setShowModal={setShowModal} />
      </div>
    </ModalContext.Provider>
  );
};

export default Layout;
