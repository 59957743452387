import React, { useCallback, useEffect, useState } from "react";
import { ArticleProps } from "../../types/types";
import { useNavigate, useParams } from "react-router-dom";
import { toggleBookmark, fetchArticleBySlug, fetchLikesBookmarks, toggleLike, incrementArticleClicks } from "../../api/articles";
import "./single-post-id.css"
import "./markdown-post-id.css";
import { useAuth } from "../../components/layout/authContext";
import { BiComment, BiHeart, BiBookmark } from "react-icons/bi";
import CommentsSection from "../../components/layout/comments/comments-section";
import ContentLoader, { Facebook } from "react-content-loader";

type FetchedArticle = {
  author: {
    data: {
      attributes: {
        username: string;
        profilePicture: {
          data: {
            attributes: {
              url: string;
            };
          };
        };
      };
    };
  };
  title: string;
  subTitle: string;
  publishDate: string;
};

const ArticleLoader = () => (
  <ContentLoader
    speed={2}
    width="full"
    viewBox="0 0 400 160"
    backgroundColor="#484b52"
    foregroundColor="#ecebeb"
    className="bg-customGrey w-full p-10"
  >
    <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
    <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
    <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
    <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
    <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
    <circle cx="20" cy="20" r="20" />
  </ContentLoader>
);

const Article = () => {
  const params = useParams();

  const [item, setItem] = useState<FetchedArticle | null>(null);
  const [likes, setLikes] = useState<number[]>([0]);
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [bookmarks, setBookmarks] = useState<number[]>([])
  const [isBookmarked, setIsBookmarked] = useState<boolean>(false);
  const [comments, setComments] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true)

  const [renderedFromMd, setRenderedFromMd] = useState<boolean>(false);

  const [articleId, setArticleId] = useState<string | null>(null);

  const { userId } = useAuth();
   
  const slug = params.slug as string;

  useEffect(() => {
    if (slug) {
      incrementArticleClicks(slug);
    }
  }, [slug]);
 
  useEffect(() => {
    if (slug) {
      fetchArticleBySlug(slug).then((res) => {
        setItem(res[0].attributes);
        setArticleId(res[0].id);
      
        if (res[0].attributes.body) {
            console.log("from markdown")
            setRenderedFromMd(true);
             const elm = document.getElementById("markdown-post-id");
             if (elm != null && res[0].attributes.content != null) {
               elm.innerHTML = res[0].attributes.content || "";
             }
        }
        else {
 const elm = document.getElementById("single-post-id");
 if (elm != null && res[0].attributes != null) {
   elm.innerHTML = res[0].attributes.content || "";
 }
        }

             setIsLoading(false);
      });
    }
  }, [slug]);

  useEffect(() => {
    if (articleId) {
fetchLikesBookmarks(articleId).then((r) => {
  setBookmarks(r.bookmarkedBy);
  setLikes(r.likedBy);
   if (userId) {
  setIsBookmarked(r.bookmarkedBy.includes(Number(userId)));
  setIsLiked(r.likedBy.includes(Number(userId)));
   }
});
    }
 
  }, [isBookmarked, isLiked, articleId, userId]);

  const handleBookmark = () => {
    if (articleId && userId) {
        toggleBookmark(articleId)
        setIsBookmarked(!isBookmarked)
    }
  }

    const handleLike = () => {
      if (articleId && userId) {
        toggleLike(articleId);
        setIsLiked(!isLiked);
      }
    };

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);



   return (
     <div className="flex flex-col items-center ">
       <div
         className="bg-customLight h-full min-h-[300px] max-h-[400px] w-full max-w-[730px] mt-[50px] rounded-tl-2xl rounded-tr-2xl p-[30px] bg-no-repeat bg-right-top"
         style={{ backgroundImage: "url(/assets/images/article/back.png)" }}
       >
         {isLoading ? (
           <ArticleLoader />
         ) : (
           <div className="flex flex-col gap-y-4">
             <h1 className="text-customDark font-bold text-[36px] leading-tight p-0">
               {item?.title}
             </h1>

             <h1 className="text-customDark text-[16px] p-0">{item?.subTitle}</h1>

             <div className="flex">
               <img
                 className="w-[56px]  h-[56px] rounded-full"
                 src={`${item?.author.data?.attributes.profilePicture.data.attributes.url}`}
                 alt="author"
               />
               <div className="flex flex-col text-customDark ml-2">
                 <p className="font-bold p-0">
                   {item?.author.data?.attributes.username}
                 </p>
                 {item && (
                   <p className="p-0 text-base">
                     {new Date(item.publishDate).toLocaleDateString("en-GB", {
                       day: "2-digit",
                       month: "short",
                       year: "numeric",
                     })}
                   </p>
                 )}
               </div>
             </div>

             <div>
               <div className="bg-white rounded-xl  max-h-[50px] p-3 flex justify-between text-customDark text-[14px] items-center">
                 <div className="flex gap-x-5 items-center">
                   <div
                     className="flex gap-x-1 items-center cursor-pointer"
                     onClick={() => handleLike()}
                   >
                     <BiHeart
                       className={`w-[18px] h-[18px] ${
                         !isLiked ? "text-customDark" : "text-customRed"
                       }`}
                     />
                     <p
                       className={`text-base p-0 ${
                         !isLiked ? "text-customDark" : "text-customRed"
                       }`}
                     >
                       {likes.length}
                     </p>
                   </div>
                   <div className="flex gap-x-1 items-center cursor-pointer">
                     <BiComment className=" w-[18px] h-[18px]" />
                     <p className="text-base p-0">{comments}</p>
                   </div>
                   <div
                     className="flex gap-x-1 items-center cursor-pointer"
                     onClick={() => handleBookmark()}
                   >
                     <BiBookmark
                       className={`w-[18px] h-[18px] ${
                         !isBookmarked ? "text-customDark" : "text-customRed"
                       }`}
                     />
                     <p
                       className={`text-base p-0 ${
                         !isBookmarked ? "text-customDark" : "text-customRed"
                       }`}
                     >
                       {bookmarks.length}
                     </p>
                   </div>
                 </div>

                 <div className="flex gap-x-3 items-center">
                   <p className="p-0 text-base">Share on</p>
                   <img
                     src="/assets/images/article/facebook.png"
                     alt="bookmark"
                     className="w-[20px] h-[20px]"
                   />
                   <img
                     src="/assets/images/article/twitter.png"
                     alt="bookmark"
                     className="w-[20px] h-[20px]"
                   />
                   <img
                     src="/assets/images/article/linkedin.png"
                     alt="bookmark"
                     className="w-[20px] h-[20px]"
                   />
                 </div>
               </div>
             </div>
           </div>
         )}
       </div>
       <div id="single-post-container" className="z-10">
         {isLoading && <ArticleLoader />}
         <div id="single-post-id" className="max-w-[730px]" />
       </div>

       <div
         id="markdown-post-id"
         className={`${!renderedFromMd && "mt-[-80px] z-5"}`}
       />

       <div
         className="bg-customArticleGrey mt-[40px] w-full max-w-[730px] rounded-[20px] px-4 py-[80px] flex flex-col items-center justify-center text-white gap-y-4"
         style={{
           backgroundImage:
             "url('/assets/images/article/article-tr.svg'), url('/assets/images/article/article-bl.svg')",
           backgroundPosition: "left top, right bottom",
           backgroundRepeat: "no-repeat, no-repeat",
         }}
       >
         <h1 className="text-3xl font-bold text-customRed">Subscribe to Crypto Pragmatist</h1>
         <p className="w-full max-w-[600px] text-center p-0 text-white">
           Crypto-Native Industry Insights & Research
         </p>
         <div className="w-full max-w-[600px] flex justify-between flex-row tems-center p-1 rounded-full border border-customRed">
           <input
             className="bg-customArticleGrey text-customTextGrey ml-4 outline-none w-full"
             placeholder="Enter Your Email"
           />

           <div className="bg-customRed rounded-full p-3 px-5">
             <p className="font-bold text-base p-0 text-white">Subscribe</p>
           </div>
         </div>
       </div>

       <CommentsSection slug={slug} />
     </div>
   ); 
}

export default Article;
