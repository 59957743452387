import React from "react";
import ContentLoader from "react-content-loader";

const ItemLoader = () => (
  <ContentLoader
    speed={2}
    width={200}
    height={300}
    viewBox="0 0 200 300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    className="animate-pulse "
  >
    <rect x="0" y="0" rx="8" ry="8" width="200" height="150" />
    <rect x="0" y="160" rx="4" ry="4" width="200" height="20" />
    <rect x="0" y="190" rx="4" ry="4" width="150" height="20" />
  </ContentLoader>
);

const ItemLoaderGroup = () => {
    return (
      <div className="min-h-[450px] flex flex-col px-[10px] xs:px-[40px] mt-4 h-full">
        <div
          className={`w-full h-full flex-grow grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 md:gap-5 lg:gap-6 mb-[20px] py-[10px]`}
        >
          {Array.from({ length: 5 }, (_, index) => (
            <ItemLoader key={index} />
          ))}
        </div>
      </div>
    );
}

export default ItemLoaderGroup;
