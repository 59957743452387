import React from "react";
import { MdOutlineClose } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";

interface MobileNavProps {
  isOpen: boolean;
  setIsMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal: (arg: boolean) => void;
  userId: string | null;
}

const MobileNav = ({
  isOpen,
  setIsMobileOpen,
  setShowModal,
  userId,
}: MobileNavProps) => {
  const navHandler = () => {
    setIsMobileOpen(false);
  };

  const loginHandler = () => {
    setIsMobileOpen(false);
    navigate("/login");
  };

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <div
        className={`fixed w-screen inset-0 bg-neutral-900 bg-opacity-90  transition-opacity z-40 ${
          !isOpen && "hidden"
        }`}
      />
      <div
        className={`h-full pl-4 pb-[50px] left-[-35px] top-[0] fixed bg-zinc-800 flex flex-col justify-between transition-all z-50 overflow-hidden" ${
          isOpen ? "w-[270px]" : "w-0 hidden"
        }`}
      >
        <div className="flex mt-4 px-4 w-[270px] justify-between items-center fixed">
          <div className="flex flex-row">
            <Link className="cursor-pointer" to="/">
              <div className="flex flex-row text-[24px] font-bold items-center ">
                <img
                  alt="logo"
                  src="/assets/images/m6-icon.png"
                  className="h-[35px] xs:h-[45px] w-[35px] xs:w-[45px] xs:ml-[16px]"
                />
                <h1 className="text-customRed ml-2 xs:ml-3 text-xl xs:text-2xl">
                  M6
                </h1>
                <h1 className="text-white ml-1 xs:ml-2 text-xl xs:text-2xl">
                  LABS
                </h1>
              </div>
            </Link>
          </div>

          <MdOutlineClose
            className="text-white text-[20px] cursor-pointer mr-4"
            onClick={() => {
              setIsMobileOpen(false);
            }}
          />
        </div>
        <div className="mt-[100px]  px-4 justify-start items-center gap-4 flex flex-col text-neutral-900 text-lg text-left font-normal bg-gradient-to-r from-homergba to-white">
          <Link
            onClick={() => navHandler()}
            className={`w-full h-[40px] p-2 flex items-center font-semibold rounded-2xl ${
              location.pathname === "/" ? "text-red-600" : "text-white"
            }`}
            to="/"
          >
            <p>Research</p>
          </Link>
          <Link
            onClick={() => navHandler()}
            className={`mt-[-10px] w-full h-[30px] p-2 ml-5 flex items-center font-semibold rounded-2xl ${
              location.pathname === "/" ? "text-red-600" : "text-white"
            }`}
            to="/"
          >
            <p>• Categories</p>
          </Link>
          <Link
            onClick={() => navHandler()}
            className={`w-full h-[30px] p-2 ml-5 flex items-center font-semibold rounded-2xl ${
              location.pathname === "/ecosystems"
                ? "text-red-600"
                : "text-white"
            }`}
            to="/ecosystems"
          >
            <p>• Ecosystems</p>
          </Link>

          <Link
            onClick={() => navHandler()}
            className={`w-full h-[40px] p-2 flex items-center font-semibold rounded-2xl ${
              location.pathname === "/market-reports"
                ? "text-red-600"
                : "text-white"
            }`}
            to="/market-reports"
          >
            <p>Market Data</p>
          </Link>

          <Link
            onClick={() => navHandler()}
            className={`w-full h-[40px] p-2 flex items-center font-semibold rounded-2xl ${
              location.pathname === "/assistant" ? "text-red-600" : "text-white"
            }`}
            to="/assistant"
          >
            <p>AI Research Assistant</p>
          </Link>

          <img
            alt="img"
            src="/assets/images/coming_soon_icon.png"
            className="h-[2px] w-full"
          />

          <Link
            onClick={() => navHandler()}
            className={`w-full h-[40px] p-2 font-semibold rounded-2xl ${
              location.pathname === "/about" ? "text-red-600" : "text-gray-300"
            }`}
            to="/about"
          >
            About Us
          </Link>
          <Link
            onClick={() => navHandler()}
            className={`Link w-full h-[40px] p-2 font-semibold rounded-2xl ${
              location.pathname === "/services"
                ? "text-red-600"
                : "text-gray-300"
            }`}
            to="/services"
          >
            Partner with us
          </Link>
          <div className="mt-[35px] ml-[-85px] px-6 py-2 bg-red-600 rounded-[20px] justify-start items-start flex">
            <button
              className="Text text-center text-white text-base font-medium leading-normal"
              onClick={() => {
                setIsMobileOpen(false);
                window.open(
                  "https://cryptopragmatist.com/subscribe?utm_source=M6Labs&utm_medium=Website&utm_campaign=HeaderButton",
                  "_blank"
                );
              }}
            >
              Subscribe
            </button>
          </div>
          {!userId && (
            <div className="mt-[10px] ml-[-115px] px-6 py-2 border border-white rounded-[20px] justify-start items-start flex">
              <button
                className="Text text-center text-white text-base font-medium leading-normal"
                onClick={loginHandler}
              >
                Login
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MobileNav;
