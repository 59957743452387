export const isNewsletter = (str: string) => {
  const words = [
    "news",
    "newsletter",
    "week",
    "day",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
    "weekly",
    "daily",
  ];
  for (let i = 0; i < words.length; i++) {
    if (
      str
        .toLowerCase()
        .split(" ")
        .some((word) => word.includes(words[i]))
    ) {
      return true;
    }
  }
  return false;
}
