import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ModalContext } from "../components/layout/layout";

export const RedButton = ({
  text,
  handler,
}: {
  text: string;
  handler: () => void;
}) => {
  return (
    <div
      onClick={handler}
      className="bg-customRed transition-transform duration-500 hover:scale-105 hover:bg-red-600 mt-2 font-bold text-whitte cursor-pointer px-4 py-2 rounded-full"
    >
      {text}
    </div>
  );
};

export const handleContact = () => {
  window.open(
    "https://o7fat38478c.typeform.com/to/BToKDy0q?utm_source=www.m6labs.co&utm_medium=newsletter&utm_campaign=sec-given-green-light-to-appeal",
    "_blank"
  );
};

const About = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <div
      className="flex flex-col items-center w-full bg-customDark  bg-contain"
      style={{
        backgroundImage: "url('/assets/images/gradient.svg')",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="flex flex-col w-[80%] min-w-[300px] max-w-[1200px] mx-4">
        <div className="flex flex-col mt-[70px] xs:mt-[150px] mb-[50px] xs:mb-[100px]">
          <div className="flex flex-row gap-x-2">
            <h1 className="text-[36px] xs:text-[48px] font-bold text-white">
              About
            </h1>
            <h1 className="text-[36px] xs:text-[48px] font-bold text-customRed">
              us
            </h1>
          </div>
          <p className="text-left text-[18px] text-customTextLight max-w-[550px]">
            At the core of our mission, we strive to forge meaningful
            collaborations with innovative projects that address real-world
            challenges, lead technological advancements, and create significant
            value for the global community
          </p>
        </div>

        <div className="flex items-center justify-center h-[100px]">
          <h1 className="text-[30px] text-center mt-2 leading-none font-bold text-customRed">
            Our Primary Objectives
          </h1>
        </div>

        <div className="grid grid-cols-1 xs:grid-cols-4 gap-y-3 xs:gap-x-4">
          <div className="flex flex-col self-center mb-[50px] justify-start items-center px-5 w-full xs:h-[250px] pt-4 rounded-2xl gap-y-4 pb-4 border border-customGrey">
            <div className="flex flex-col w-full items-center gap-y-3 pb-4">
              <b className="text-customRed text-[20px] text-center">
                {" "}
                Investor Engagement{" "}
              </b>
              <p className="text-left text-[17px] text-customTextLight">
                Cultivating lasting relationships with investors through
                strategic visibility and educational initiatives.
              </p>
            </div>
          </div>

          <div className="flex flex-col self-center mb-[50px] justify-start items-center px-5 w-full xs:h-[250px] pt-4 rounded-2xl gap-y-4 pb-4 border border-customGrey">
            <div className="flex flex-col w-full items-center gap-y-3 pb-4">
              <b className="text-customRed text-[20px] text-center">
                User Education{" "}
              </b>
              <p className="text-left text-[17px] text-customTextLight">
                Crafting informative content to enhance user understanding and
                engagement in the crypto realm.
              </p>
            </div>
          </div>

          <div className="flex flex-col self-center mb-[50px] justify-start items-center px-5 w-full xs:h-[250px] pt-4 rounded-2xl gap-y-4 pb-4 border border-customGrey">
            <div className="flex flex-col w-full items-center gap-y-3 pb-4">
              <b className="text-customRed text-[20px] text-center">
                Project Visibility
              </b>
              <p className="text-left text-[17px] text-customTextLight">
                Amplifying your project's presence across various platforms,
                leveraging social media, newsletters, and partnerships with
                industry frontrunners.
              </p>
            </div>
          </div>

          <div className="flex flex-col self-center mb-[50px] justify-start items-center px-5 w-full xs:h-[250px] pt-4 rounded-2xl gap-y-4 pb-4 border border-customGrey">
            <div className="flex flex-col w-full items-center gap-y-3 pb-4">
              <b className="text-customRed text-[20px] text-center">
                Global Distribution
              </b>
              <p className="text-left text-[17px] text-customTextLight">
                Ensuring your project reaches a worldwide audience with our
                comprehensive translation and distribution services.
              </p>
            </div>
          </div>
        </div>

        <div className="mb-[100px] mt-[50px] self-center hidden xs:flex flex-col w-full max-w-[650px] mx-4 gap-y-2 items-center justify-center">
          <div className="flex flex-row gap-x-2 text-center text-[30px] xs:text-[48px]">
            <h1 className=" font-bold text-customRed">Partner</h1>
            <h1 className="font-bold text-white">with us?</h1>
          </div>
          <p className="text-center text-customTextLight max-w-[400px] mb-2">
            Eager to elevate your brand? Let's discuss how our tailored approach
            can drive your project's growth
          </p>
          <RedButton
            text="Discover Our Partnership Plans"
            handler={handleContact}
          />
        </div>
      </div>
    </div>
  );
};

export default About;
