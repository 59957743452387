import React, { useState } from 'react';
import { handleResetPassword } from '../api/user';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const nav = useNavigate();

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    const [isValid, setIsValid] = useState(true);

      const mutation = useMutation(handleResetPassword, {
        onSuccess: (data) => {
          nav("/login")
          console.log("Reset password response:", data);
        },
        onError: (error) => {
         setIsValid(false)
         setNewPassword("")
         setConfirmPassword("")
          console.error("Reset password error:", error);
          alert("Unknown error, please try again")
        },
      });

  const onSubmit = (code: string | null, password: string, passwordConfirmation: string) => {
    if (code && password?.length > 5 && (passwordConfirmation === password)) {
    mutation.mutate({ code, password, passwordConfirmation });
  };
}


    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="flex flex-col w-full max-w-[450px] mx-4 p-4 gap-y-4 bg-customGrey rounded-2xl">
          <div className=" mb-[32px] w-full min-w-[300px]">
            <div className="Subtitle text-white font-semibold text-base leading-none mb-[16px]">
              New password
            </div>
            <div
              className={`PasswordForm flex flex-row justify-between items-center w-full h-12 min-w-[300px] rounded-[28px] border border-opacity-40 pl-[24px] pr-[16px] ${
                isValid ? "border-slate-400" : "border-customRed"
              }`}
            >
              <input
                className="flex-grow w-full text-sm md:text-lg text-white bg-transparent outline-none"
                value={newPassword}
                type="password"
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
          </div>

          <div className=" mb-[32px] w-full min-w-[300px]">
            <div className="Subtitle text-white font-semibold text-base leading-none mb-[16px]">
             Confirm new password
            </div>
            <div
              className={`PasswordForm flex flex-row justify-between items-center w-full h-12 min-w-[300px] rounded-[28px] border border-opacity-40 pl-[24px] pr-[16px] ${
                isValid ? "border-slate-400" : "border-customRed"
              }`}
            >
              <input
                className="flex-grow w-full text-sm md:text-lg text-white bg-transparent outline-none"
                value={confirmPassword}
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>

          <div
          onClick={() => onSubmit(code, newPassword, confirmPassword)}
            className={`text-white flex items-center justify-center rounded-full px-1 py-2 font-bold ${
              newPassword === confirmPassword && newPassword.length > 6
                ? "bg-customRed cursor-pointer hover:animate-pulse"
                : "bg-customRed opacity-50"
            }`}
          >
            Confirm
          </div>
        </div>
      </div>
    );
};

export default ResetPassword;