import jsPDF from "jspdf";
import { IMessage } from "../types/types";

const getImageData = (path: string) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      const context = canvas.getContext("2d");
      context?.drawImage(image, 0, 0);
      resolve(canvas.toDataURL("image/png"));
    };
    image.onerror = reject;
    image.src = path;
  });
};

export const exportPDF = async (messages: IMessage[]) => {
  const doc = new jsPDF();
  let yOffset = 22; // Initial vertical offset for the first line of text
  const pageWidth = doc.internal.pageSize.getWidth();
  const padding = 5;
  const maxLineWidth = pageWidth - padding * 2; // Calculate the maximum line width

  const imageData = await getImageData("/assets/images/m6-icon.png"); // Replace with your actual function to get base64 data
  const imageWidth = 10; // Set the desired width or calculate it
  const imageHeight = 10; // Set the desired height or calculate it
  const xPosition = pageWidth / 2 - imageWidth / 2; // Centers the image

    const addImageToPage = () => {
      //@ts-ignore
      doc.addImage(imageData, "PNG", xPosition, 5, imageWidth, imageHeight);
    };

    
  doc.setFillColor(37, 39, 44); // "25272C" in RGB
  doc.rect(
    0,
    0,
    doc.internal.pageSize.width,
    doc.internal.pageSize.height,
    "F"
  );
  // Set styles based on the message sender
  doc.setFont("helvetica"); // Default font
  doc.setFontSize(12); // Default size
  doc.setTextColor(255, 255, 255);

  messages.forEach((message, index) => {
    const prefix = message.isUser ? "User: " : "Assistant: ";
    const text = prefix + message.content;
    // Wrap text and calculate text block height
    const lines = doc.splitTextToSize(text, maxLineWidth);
    const textBlockHeight = lines.length * 10; // Approximate line height

    // Add the wrapped text
    doc.text(lines, padding, yOffset);

    // Increment yOffset by the height of the text block plus some padding
    yOffset += textBlockHeight;

    // Check if a new page is needed
    if (yOffset >= doc.internal.pageSize.getHeight() - 10) {
      doc.addPage();
      yOffset = 10; // Reset the vertical offset for the new page
        addImageToPage();
    }
  });

  addImageToPage();

  doc.save("m6labs_bot_export.pdf");
};