import React from 'react';

const TopAds = () => {
  
    return (
      <div
        onClick={() =>
          window.open(
            "https://cryptopragmatist.com/subscribe?utm_source=M6Labs&utm_medium=Website&utm_campaign=HomeBanner",
            "_blank"
          )
        }
        className="hidden xs:flex items-center justify-center cursor-pointer relative hover:scale-[101%] transition-transform duration-500  w-full pt-[15px] xs:pt-[20px] px-[15px] xs:px-[24px]"
      >
        <div className="relative flex items-center justify-center w-[70%] min-w-[600px] max-w-[900px] p-4 h-[100px]  rounded-lg bg-black col-span-3 xs:col-span-1">
          <div className="flex flex-col items-end font-roboto mb-[10px]">
            <h1 className="text-white font-extrabold uppercase italic text-[30px]">
              “I don’t read the crypto pragmatist.”
            </h1>
            <h1 className="text-[#3AC135] ml-2 font-bold font-italic uppercase text-[18px] mt-[-10px]">
              -Certified Brokie, Aged 25
            </h1>
          </div>
  

          <div className="absolute bottom-[5px] text-[13px] flex  self-center left-1/2 transform -translate-x-1/2 text-[#B3B3B3]">
            Don’t be this guy, click for a totally FREE subscription.
          </div>
        </div>
      </div>
    );
};

export default TopAds;