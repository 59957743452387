import React from 'react';

const Partners = () => {
    return (
      <div className="hidden xs:flex flex-col col-span-3 xs:col-span-2 w-full p-2 mt-4 xs:mt-0">
        <h1 className="text-white text-center font-bold text-[24px] mb-4">
          Trusted Partners
        </h1>

        <div className="flex items-center justify-evenly flex-wrap w-full xs:px-[5%] gap-[15px]">
          <div className="flex items-center justify-center">
            <img
              className="w-[135px] h-[35px] object-contain"
              src="/assets/images/coinbureau_logo.png"
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              className="w-[95px] h-[27px] object-contain"
              src="/assets/images/techflow_logo.png"
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              className="w-[135px] h-[22px] object-contain"
              src="/assets/images/footprint_logo.png"
            />
          </div>

          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center bg-white rounded-lg p-2">
              <img
                className="max-w-[105px] h-[10px]  object-contain "
                src="/assets/images/foresight_logo.png"
              />
            </div>
          </div>

          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center bg-white rounded-lg p-2">
              <img
                className="w-[134px] h-[18px] object-contain"
                src="/assets/images/block_tempo_logo.png"
              />
            </div>
          </div>
          <div className="flex items-center justify-center bg-white rounded-lg p-2 max-h-[30px] self-center">
            <img
              className="w-[134px] object-contain"
              src="/assets/images/token_unlocks_logo.png"
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              className="w-[134px]  object-contain"
              src="/assets/images/bubble_maps_logo.png"
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              className="w-[94px]  object-contain"
              src="/assets/images/odaily_logo.png"
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              className="w-[95px] h-[55px] object-contain"
              src="/assets/images/chain_edge_logo.png"
            />
          </div>
        </div>
      </div>
    );
};

export default Partners;