import axios from "axios";

export const getTokenData = async (query: string) => {
  const searchUrl = `https://api.coingecko.com/api/v3/search?query=${encodeURIComponent(
    query
  )}`;

  try {
    // First API call to search for the coin
    const searchResponse = await axios.get(searchUrl);
    const searchData = searchResponse.data;
    if (searchData.coins && searchData.coins.length > 0) {
      const firstCoin = searchData.coins[0];
      const apiSymbol = firstCoin.id;

      // Second API call to fetch details for the first coin
      const detailsUrl = `https://api.coingecko.com/api/v3/coins/${apiSymbol}?localization=false&tickers=true&community_data=true&sparkline=false`;
      const detailsResponse = await axios.get(detailsUrl);
      const detailsData = detailsResponse.data;

      // Third API call to fetch market data for the coin
      const marketUrl = `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${apiSymbol}&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en`;
      const marketResponse = await axios.get(marketUrl);
      const marketData = marketResponse.data;

      // Combine detailsData and marketData
      return { detailsData, marketData };
    } else {
      return { error: "No results found" };
    }
  } catch (error) {
    console.error("Error:", error);
    return { error: "An error occurred while fetching data" };
  }
};

export const getNewTokens = async () => {
  const apiUrl = `https://pro-api.coingecko.com/api/v3/coins/list/new?&x_cg_pro_api_key=${process.env.REACT_APP_COINGECKO_API_KEY}`;

  try {
    const response = await axios.get(apiUrl);

    return response.data;
  } catch (error) {
    console.error("Error fetching new tokens:", error);
    throw error;
  }
}

export const getTrendingTokens = async () => {
  console.log("Fetching trending tokens...")
  const apiUrl = `https://api.coingecko.com/api/v3/search/trending`;

  try {
    const response = await axios.get(apiUrl);

    return response.data;
  } catch (error) {
    console.error("Error fetching new tokens:", error);
    throw error;
  }
};

export const getOhlc = async (query: string, days: string) => {
  const searchUrl = `https://api.coingecko.com/api/v3/search?query=${encodeURIComponent(
    query
  )}`;

  try {
    // First API call to search for the coin
    const searchResponse = await axios.get(searchUrl);
    const searchData = searchResponse.data;
    if (searchData.coins && searchData.coins.length > 0) {
      const firstCoin = searchData.coins[0];
      const apiSymbol = firstCoin.id;

      // Second API call to fetch details for the first coin
      const detailsUrl = `https://pro-api.coingecko.com/api/v3/coins/${apiSymbol}/ohlc?vs_currency=usd&days=${days}&interval=daily&x_cg_pro_api_key=${process.env.REACT_APP_COINGECKO_API_KEY}`;
      const detailsResponse = await axios.get(detailsUrl);
      const data = detailsResponse.data;

      return data;
    } else {
      return { error: "No results found" };
    }
  } catch (error) {
    console.error("Error:", error);
    return { error: "An error occurred while fetching data" };
  }
};

// async function googleSearch(searchKeyword: string): Promise<string> {
//   const url: string = "https://google.serper.dev/search";
//   const payload: string = JSON.stringify({ q: searchKeyword });

//   try {
//     const response = await axios.post(url, payload, {
//       headers: {
//         "X-API-KEY": process.env.REACT_APP_GOOGLE_SERPER_API_KEY,
//         "Content-Type": "application/json",
//       },
//     });

//     console.log("RESPONSE:", response.data);
//     return response.data;
//   } catch (error) {
//     console.error("Error in googleSearch:", error);
//     throw error;
//   }
// }


// async function webScraping(
//   objective: string,
//   url: string
// ): Promise<string | any> {
//   console.log("Scraping website...");

//   try {
//     const response = await axios.post(
//       `https://chrome.browserless.io/content?token=${browserlessApiKey}`,
//       {
//         url: url,
//       },
//       {
//         headers: {
//           "Cache-Control": "no-cache",
//           "Content-Type": "application/json",
//         },
//       }
//     );

//     if (response.status === 200) {
//       const dom = new JSDOM(response.data);
//       const text = dom.window.document.body.textContent || "";
//       console.log("CONTENT:", text);

//       if (text.length > 10000) {
//         const output = await summary(objective, text);
//         return output;
//       } else {
//         return text;
//       }
//     } else {
//       console.log(`HTTP request failed with status code ${response.status}`);
//       throw new Error(
//         `HTTP request failed with status code ${response.status}`
//       );
//     }
//   } catch (error) {
//     console.error("Error in webScraping:", error);
//     throw error;
//   }
// }



export const sendMessageToServer = async (
  message: string,
  isUser: boolean,
  user: string | null
) => {
  const apiUrl = `${process.env.REACT_APP_STRAPI_URL}/api/assistant-messages`;

  const dataPayload = {
    data: {
      isUser: isUser,
      content: message,
      ...(user && { user: user }),
    },
  };

  try {
    const response = await axios.post(apiUrl, dataPayload);

    return response.data;
  } catch (error) {
    console.error("Error sending message:", error);
    throw error;
  }
};

export const getConversationByUserId = async (userId: string) => {
  const apiUrl = `${process.env.REACT_APP_STRAPI_URL}/api/assistant-messages?filters[user][id][$eq]=${userId}`;

  try {
    const response = await axios.get(apiUrl);

    return response.data;
  } catch (error) {
    console.error("Error fetching conversation:", error);
    throw error;
  }
};

const getConversationIdsByUserId = async (userId: string) => {
  const apiUrl = `${process.env.REACT_APP_STRAPI_URL}/api/assistant-messages?filters[user][id][$eq]=${userId}&fields=id`;

  try {
    const response = await axios.get(apiUrl);

    return response.data.data.map((message: any) => message.id);
  } catch (error) {
    console.error("Error fetching conversation IDs:", error);
    throw error;
  }
};

const deleteMessageById = async (messageId: string) => {
  const apiUrl = `${process.env.REACT_APP_STRAPI_URL}/api/assistant-messages/${messageId}`;

  try {
    await axios.delete(apiUrl);
  } catch (error) {
    console.error(`Error deleting message with ID ${messageId}:`, error);
    throw error;
  }
};

export const deleteEntireConversation = async (userId: string) => {
  try {
    const messageIds = await getConversationIdsByUserId(userId);

    // Delete each message individually
    for (const messageId of messageIds) {
      await deleteMessageById(messageId);
    }

    console.log("Conversation deleted successfully.");
  } catch (error) {
    console.error("Error deleting conversation:", error);
    throw error;
  }
};