import axios from "axios";

export const fetchComments = async (slug: string) => {
  try {
    const commentsResponse = await axios.get(
      `${process.env.REACT_APP_STRAPI_URL}/api/comment-manager/comments/${slug}`
    );
    const comments = commentsResponse.data.comments;

    const fetchUserDetails = async (userId: number) => {
      const response = await axios.get(
        `${process.env.REACT_APP_STRAPI_URL}/api/users/${userId}?populate=profilePicture`
      );
      return response.data;
    };

    const populateCommentsWithUserDetails = async (comment: any) => {
      const userDetails = await fetchUserDetails(comment.author.id);
      let populatedSubcomments = [];

      if (comment.subcomments && comment.subcomments.length > 0) {
        const subcommentsPromises = comment.subcomments.map(
          populateCommentsWithUserDetails
        );
        populatedSubcomments = await Promise.all(subcommentsPromises);
      }

      return {
        ...comment,
        author: {
          ...comment.author,
          profilePicture: userDetails.profilePicture?.url,
        },
        subcomments: populatedSubcomments,
      };
    };

    const commentsWithUserDetailsPromises = comments.map(
      populateCommentsWithUserDetails
    );
    return await Promise.all(commentsWithUserDetailsPromises);
  } catch (error) {
    console.error(error);
    throw new Error("Error fetching comments with user details");
  }
};


export const fetchCommentsCount = async (slug: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_STRAPI_URL}/api/comment-manager/comments/${slug}/count`
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
};

export const addComment = async (slug: string, content: string) => {

    const payload = {
      content: content,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_STRAPI_URL}/api/comment-manager/comments/${slug}`,
            payload
      );
      console.log(response.data);

      return response.data;
    } catch (error) {
      console.error(error);
    }
};

export const addSubComment = async (parentId: string, content: string) => {
     const payload = {
       content: content,
     };

     try {
       const response = await axios.post(
         `${process.env.REACT_APP_STRAPI_URL}/api/comment-manager/subcomments/${parentId}`,
         payload
       );
       console.log(response.data);

       return response.data;
     } catch (error) {
       console.error(error);
     }
};

export const populateAuthor = async (authorId: string) => {

}