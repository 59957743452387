export const trimAndAddEllipsis = (input: string, amount: number): string => {
  if (input.length > amount) {
    const words = input.split(' ')
    let trimmed = ''
    for (const word of words) {
      if ((trimmed + word).length <= amount - 3) {
        trimmed += word + ' '
      } else {
        break
      }
    }
    return trimmed.trim() + '...'
  }
  return input
}
