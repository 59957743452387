
const TopAdsMobile = () => {
  return (
    <div className="grid xs:hidden mt-[20px] grid-cols-3 w-full gap-x-5 pt-[15px] xs:pt-[20px] px-[15px] xs:px-[24px]">
      <div className="flex flex-col relative w-full p-4 h-[140px] rounded-lg bg-black col-span-3 xs:col-span-1">
        <div className="relative flex items-center flex-col w-full p-4 h-[130px] rounded-lg bg-black col-span-3 xs:col-span-1">
          <div className="flex flex-col items-end font-roboto">
            <h1 className="text-white font-extrabold uppercase italic text-[20px]">
              “I don’t read the crypto pragmatist.”
            </h1>
            <h1 className="text-[#3AC135] ml-2 font-bold font-italic uppercase text-[14px] ">
              -Certified Brokie, Aged 25
            </h1>
          </div>

          <div className="absolute bottom-[-10px] text-[11px] flex  self-center text-[#B3B3B3]">
            Don’t be this guy, click for a totally FREE subscription.
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopAdsMobile
