import React, { useContext, useEffect } from "react";
import { RedButton, handleContact } from "./about";
import { useNavigate } from "react-router-dom";
import { ModalContext } from "../components/layout/layout";


const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    
  return (
    <div
      className="flex flex-col items-center w-full bg-customDark  bg-contain"
      style={{
        backgroundImage: "url('/assets/images/gradient.svg')",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="flex flex-col w-[95%] xs:w-[75%] min-w-[300px] max-w-[1100px] mx-4">
        <div className="flex flex-col mt-[50px] xs:mt-[150px] mb-[50px] xs:mb-[100px]">
          <div className="flex flex-col leading-tight xs:flex-row gap-x-2 text-center mt-[50px] xs:mt-0">
            <h1 className="text-[36px] text-left xs:text-[48px] font-bold text-customRed">
              Research
            </h1>
            <h1 className="text-[36px] text-left xs:text-[48px] font-bold text-white">
              as a Service
            </h1>
          </div>
          <p className="text-left text-[18px] text-customTextLight max-w-[550px] mt-[10px] xs:mt-0">
            Our expert team delivers comprehensive services, bridging the gap
            between your project and the community with actionable insights and
            strategic outreach.
          </p>
        </div>

        <div
          className="flex xs:hidden flex-col xs:flex-row col-span-3 xs:col-span-2 w-full h-[210px] xs:h-[140px] p-2 xs:p-4 rounded-lg bg-customGrey mb-[50px]"
          style={{
            backgroundImage: "url('/assets/images/gradient.svg')",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h1 className="text-white font-bold text-[24px] xs:max-w-[100px] pt-2 pl-2 xs:p-0">
            Trusted Partners
          </h1>

          <div className="grid grid-cols-3 xs:grid-cols-5 grid-rows-3 xs:grid-rows-2 gap-1 xs:gap-3 w-full xs:px-[5%]">
            <div className="flex items-center justify-center">
              <img
                className="w-[135px] h-[35px] object-contain"
                src="/assets/images/coinbureau_logo.png"
              />
            </div>
            <div className="flex items-center justify-center">
              <img
                className="w-[75px] h-[22px] object-contain"
                src="/assets/images/techflow_logo.png"
              />
            </div>
            <div className="flex items-center justify-center">
              <img
                className="w-[112px] h-[18px] object-contain"
                src="/assets/images/footprint_logo.png"
              />
            </div>

            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center bg-white rounded-lg p-2">
                <img
                  className="max-w-[105px] h-[10px]  object-contain "
                  src="/assets/images/foresight_logo.png"
                />
              </div>
            </div>

            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center bg-white rounded-lg p-2">
                <img
                  className="w-[134px] h-[18px] object-contain"
                  src="/assets/images/block_tempo_logo.png"
                />
              </div>
            </div>
            <div className="flex items-center justify-center bg-white rounded-lg p-2 max-h-[30px] self-center">
              <img
                className="w-[134px] object-contain"
                src="/assets/images/token_unlocks_logo.png"
              />
            </div>
            <div className="flex items-center justify-center">
              <img
                className="w-[134px]  object-contain"
                src="/assets/images/bubble_maps_logo.png"
              />
            </div>
            <div className="flex items-center justify-center">
              <img
                className="w-[94px]  object-contain"
                src="/assets/images/odaily_logo.png"
              />
            </div>
            <div className="flex items-center justify-center">
              <img
                className="w-[45px] h-[45px] object-contain"
                src="/assets/images/chain_edge_logo.png"
              />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center h-[100px]">
          <h1 className="text-[30px] text-center mt-2 leading-none font-bold text-customRed">
            Services Offered
          </h1>
        </div>

        <div className="flex flex-col xs:flex-row gap-x-4">
          <div className="flex flex-col self-center mb-[50px] justify-start items-center px-5 w-full max-w-[750px] xs:h-[220px] rounded-2xl gap-y-4 pb-4 border border-customGrey">
            <div className="flex flex-col w-full items-center gap-y-3 my-4">
              <b className="text-customRed text-[20px] text-center">
                {" "}
                Industry & Narrative Reports{" "}
              </b>
              <p className="text-left text-[17px] text-customTextLight">
                Receive top-tier analyses and insights on emergent trends and
                technologies, distilled into clear, actionable intelligence.
              </p>
            </div>
          </div>

          <div className="flex flex-col self-center mb-[50px] justify-start items-center px-5 w-full max-w-[750px] xs:h-[220px] rounded-2xl gap-y-4 pb-4 border border-customGrey">
            <div className="flex flex-col w-full items-center gap-y-3 my-4">
              <b className="text-customRed text-[20px] text-center">
                Newsletter & Social Content
              </b>
              <p className="text-left text-[17px] text-customTextLight">
                Benefit from our specialized team crafting bespoke content to
                broaden your reach and forge deeper connections with your
                audience.
              </p>
            </div>
          </div>

          <div className="flex flex-col self-center mb-[50px] justify-start items-center px-5 w-full max-w-[750px] xs:h-[220px] rounded-2xl gap-y-4 pb-4 border border-customGrey">
            <div className="flex flex-col w-full items-center gap-y-3 my-4">
              <b className="text-customRed text-[20px] text-center">
                Ecosystem Coverage
              </b>
              <p className="text-left text-[17px] text-customTextLight ">
                Gain from our in-depth expertise in crypto ecosystems,
                propelling your project's growth with our dedicated research.
              </p>
            </div>
          </div>
        </div>

        <div className="mb-[100px] mt-[50px] self-center hidden xs:flex flex-col w-full max-w-[650px] mx-4 gap-y-2 items-center justify-center">
          <div className="flex flex-row gap-x-2 text-center text-[30px] xs:text-[48px]">
            <h1 className="font-bold text-white">Interested in a </h1>
            <h1 className=" font-bold text-customRed">Partnership</h1>
            <h1 className="font-bold text-white">?</h1>
          </div>
          <p className="text-center text-customTextLight max-w-[400px] mb-2">
            We're ready to discuss strategies to expand your brand's influence.
          </p>
          <RedButton text="Connect With Our Experts" handler={handleContact} />
        </div>
      </div>
    </div>
  );
};

export default Services;
