import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Error from "./pages/error";
import Layout from "./components/layout/layout";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./components/layout/authContext";
import Research from "./pages/research";
import Article from "./pages/article/article";
import Assistant from "./pages/assistant";
import Ecosystems from "./pages/ecosystems";
import Account from "./pages/account";
import Login from "./pages/login";
import Redirect from "./pages/redirect";
import About from "./pages/about";
import Services from "./pages/services";
import TerminalLayout from "./components/layout/terminal-layout/terminalLayout";
import ResetPassword from "./pages/reset-password";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import MarketReports from "./pages/market-reports";

ReactGA.initialize(process.env.REACT_APP_GA4_ID as string);

const usePageTracking = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);
};

function App() {
   usePageTracking();
  const queryClient = new QueryClient();

  const withTerminalLayout = <P extends object>(
    WrappedComponent: React.ComponentType<P>
  ) => {
    const ComponentWithLayout = (props: P) => (
      <TerminalLayout>
        <WrappedComponent {...props} />
      </TerminalLayout>
    );

    return ComponentWithLayout;
  };

  const WrappedResearch = withTerminalLayout(Research);
  const WrappedAssistant = withTerminalLayout(Assistant);
  const WrappedEcosystems = withTerminalLayout(Ecosystems);
  const WrappedReports = withTerminalLayout(MarketReports);

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Layout>
            <Routes>
              <Route path="/" element={<WrappedResearch />} />
              <Route path="/assistant" element={<WrappedAssistant />} />
              <Route path="/ecosystems" element={<WrappedEcosystems />} />
              <Route path="/market-reports" element={<WrappedReports />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/account" element={<Account />} />
              <Route path="/login" element={<Login />} />
              <Route path="/passreset" element={<ResetPassword />} />
              <Route path="/connect/google/redirect" element={<Redirect />} />
              <Route path="/error" element={<Error />} />
              <Route path="/p/:slug" element={<Article />} />
            </Routes>
          </Layout>
        </Router>
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
