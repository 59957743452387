import react, { useState, useEffect } from "react";
import NewsletterTile from "./articles-grid-item";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { fetchedArticleProps } from "../../../../types/types";

type NewsletterGridProps = {
  articles: any;
};

const ArticlesGrid = ({ articles }: NewsletterGridProps) => {

  return (
    <div className="h-full flex flex-col px-[10px] xs:px-[40px]">
      <div
        className={`w-full h-full flex-grow grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 md:gap-5 lg:gap-6 py-[10px]`}
      >
        {articles.length ? (
          articles.map((element: any, index: number) => (
            <NewsletterTile
              img={element.thumbnailUrl.url}
              title={element.title}
              subTitle={element.subTitle}
              slug={element.slug}
              date={element.publishDate}
              key={index}
              index={index}
            />
          ))
        ) : (
          <div className="text-center uppercase"></div>
        )}
      </div>
    </div>
  );
};

export default ArticlesGrid;
