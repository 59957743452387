import React from "react";

interface SuggestionButtonProps {
  handler: (text: string) => void;
  text: string;
  isMobileHidden?: boolean;
}

const SuggestionButton = ({
  handler,
  text,
  isMobileHidden
}: SuggestionButtonProps) => {

  return (
    <div
      onClick={() => handler(text)}
      className={`transition-all duration-500 hover:scale-105 hover:text-white cursor-pointer drop-shadow-lg
       text-neutral-200 rounded-2xl bg-[#333333] bg-opacity-80 hover:bg-opacity-100 px-3 py-2 flex items-center justify-center ${
         isMobileHidden ? "hidden xs:flex" : "w-full xs:w-auto"
       }`}
    >
      <p>{text}</p>
    </div>
  );
};

export default SuggestionButton;
