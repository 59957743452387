import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchedArticleProps } from "../../../types/types";
import { useSearchArticles } from "../../../api/hooks";
import { getCountOfArticles } from "../../../api/articles";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

interface SerachBarProps {
  type: "Research" | "News" | "Ecosystems" | "Reports";
  searchInput: string;
  setSearchInput: (searchInput: string) => void;
  sortByClicks: boolean;
  setSortByClicks: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchBar = ({
  searchInput,
  setSearchInput,
  type,
  sortByClicks,
  setSortByClicks
}: SerachBarProps) => {
  const [isClosed, setIsClosed] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [articlesAmount, setArticlesAmount] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const { data: articles, isLoading, isError, error } = useSearchArticles({
    searchQuery: searchInput,
    page: 1,
    pageSize: 20,
    type: type,
  });

  useEffect(() => {
    inputRef.current?.focus();
    setIsClosed(false);
  }, [searchInput]);

  useEffect(() => {
   getCountOfArticles().then((count) => {
     setArticlesAmount(count.count);
   });
  }, []);

  const highlightText = (text: string, highlight: string) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} className="text-red-500">
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const handleSuggestionClick = (suggestion: string, slug: string) => {
    navigate(`/:p/${slug}`);
    setSearchInput(suggestion);
  };

  const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setIsClosed(true);
      console.log("Enter key pressed");
    }
  };

   const modalRef = useRef<HTMLDivElement>(null);

   const handleClickOutside = (event: MouseEvent) => {
     if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
       setIsClosed(true); // Close the modal
     }
   };

   useEffect(() => {
     document.addEventListener("mousedown", handleClickOutside);
     return () => {
       document.removeEventListener("mousedown", handleClickOutside);
     };
   }, []);


  return (
    <div className="flex justify-center px-[2.5%] py-3 xs:py-4 relative">
      <div
        className={`flex z-20 items-center w-full h-[45px] xs:h-[55px] bg-zinc-800 rounded-full shadow border xs:border-2 px-5 xs:px-6 gap-3 ${
          searchInput.length > 0 ? "border-animate" : "border-customRed"
        }`}
      >
        <img
          alt="search"
          src="/assets/images/search.png"
          className="w-4 xs:w-6 h-4 xs:h-6"
        />
        <input
          className="flex-grow text-sm md:text-lg text-white bg-transparent outline-none"
          ref={inputRef}
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onClick={() => setSearchInput("")}
          placeholder={`Search our database ${
            articlesAmount > 0 ? `of ${articlesAmount} articles` : ""
          }`}
          onKeyPress={handleEnterPress}
        />
        {/* <img alt="voice" src="/assets/images/mic.png" className="w-4 h-6" /> */}
      </div>
      <div
        onClick={() => setIsSortOpen(!isSortOpen)}
        className="h-[45px] relative w-[120px] ml-4 flex items-center justify-center xs:h-[55px] shadow border xs:border-2 px-3 gap-x-2 border-customRed rounded-full font-bold cursor-pointer"
      >
        <p>Sort by</p>
        {isSortOpen ? <FaAngleUp /> : <FaAngleDown />}
      </div>

      {isSortOpen && (
        <div className="absolute grid grid-rows-2 shadow-xl z-20 animate-fade-in rounded-lg w-[130px] bg-customGrey h-[80px] right-[40px] top-[90px] border xs:border-2 border-customRed">
          <div
            onClick={() => {
              setSortByClicks(false);
              setIsSortOpen(false);
            }}
            className={`flex items-center justify-center font-bold underline hover:text-customRed  cursor-pointer ${
              !sortByClicks
                ? "bg-[#32343b] text-white"
                : "bg-customGrey text-gray-300"
            }`}
          >
            Date
          </div>

          <div
            onClick={() => {
              setSortByClicks(true);
              setIsSortOpen(false);
            }}
            className={`flex items-center justify-center font-bold underline hover:text-customRed cursor-pointer ${
              sortByClicks
                ? "bg-[#32343b] text-white"
                : "bg-customGrey text-gray-300"
            }`}
          >
            Popularity
          </div>
        </div>
      )}

      {!isClosed && searchInput && articles && articles?.length > 0 && (
        <div
          ref={modalRef}
          className="absolute z-10 animate-fade-in rounded-lg w-[87%] bg-[#1A1A1A] flex flex-col mt-4 pb-3 xs:pb-6 pt-[60px] mr-[125px]"
        >
          {articles?.map((article: fetchedArticleProps, index: number) => (
            <div
              key={index}
              className={`${
                index !== articles?.length - 1
                  ? "border-b border-customTextGrey"
                  : ""
              } py-3`}
            >
              <p
                className="px-4 text-left text-md md:text-lg text-white w-full cursor-pointer"
                onClick={() =>
                  handleSuggestionClick(article.title, article.slug)
                }
              >
                {highlightText(article.title, searchInput)}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
