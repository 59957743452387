import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import FooterMostPopular from './footer-most-popular';
// import RecentNews from '../recent-news/recent-news';

type FooterPropsT = {
  setShowModal: (arg: boolean) => void
}
const Footer = (props: FooterPropsT) => {
  const {setShowModal} = props
  const location = useLocation();
  

    return (
      <div className="rounded-[20px] bg-white py-2 mx-[1%] mt-[50px] xs:mt-[80px] md:mt-[70px]">
        <div className="flex flex-col xl:flex-row w-[full] px-[15px] xs:px-[20px] pt-[20px]">
          <div className="flex flex-col items-start justify-between mm-4 md:w-[50%] md:min-w-[486px]">
            <img
              alt="logo"
              src="/assets/images/m6-icon.png"
              className="h-[60px] xs:h-[70px] w-[60px] xs:w-[70px] mb-[12px] xs:mb-[20px]"
            />
            <img
              alt="logo"
              src="/assets/images/m6-logo-dark.png"
              className="h-[25px] xs:h-[37px] xs:w-[180px] mb-[5px] xs:mb-[15px]"
            />

            <div className="text-center text-lg xs:text-xl mb-4">
              <span className="text-black  font-normal font-['Helvetica'] leading-tight text-base p-0">
                ©{" "}
              </span>
              <span className="text-black font-bold font-['Helvetica'] leading-tight text-base p-0">
                M6 Labs{" "}
              </span>
              <span className="text-black font-normal font-['Helvetica'] leading-tight text-base p-0">
                2023, All Rights Reserved.
              </span>
            </div>
          </div>
          <div className="w-[40%] flex flex-col justify-between mb-4 min-w-[320px]">
            <p className="font-semibold text-black text-2xl mb-[10px] xs:mt-[20px] xl:mt-0 p-0">
              Most Popular
            </p>

            <FooterMostPopular />

            <Link
              className="border-b border-red-600 text-red-600 p-2 w-[100px] uppercase font-bold mt-[10px] cursor-pointer"
              to="/"
            >
              View All
            </Link>
          </div>

          <div className="w-[20%] flex flex-col min-w-[150px] gap-y-2 text-[14px] xs:text-[18px]">
            <p className="text-black font-semibold text-2xl p-0">Quick Links</p>

            <Link
              onClick={() => {
                location.pathname === "/about" && window.scrollTo(0, 0);
              }}
              className="text-neutral-900 cursor-pointer hover:underline"
              to="/about"
            >
              About us
            </Link>
            <Link
              className="text-neutral-900 cursor-pointer hover:underline"
              to="https://o7fat38478c.typeform.com/to/BToKDy0q?utm_source=www.m6labs.co&utm_medium=newsletter&utm_campaign=sec-given-green-light-to-appeal"
            >
              Partner with Us
            </Link>
            <p
              className="text-neutral-900 cursor-pointer hover:underline mb-4 xs:mb-0 text-base"
              onClick={() =>
                window.open(
                  "https://cryptopragmatist.com/subscribe?utm_source=M6Labs&utm_medium=Website&utm_campaign=FooterButton",
                  "_blank"
                )
              }
            >
              Subscribe
            </p>
          </div>
        </div>
      </div>
    );
};

export default Footer;