import axios from "axios";
import { ArticleProps, fetchedArticleProps } from "../types/types";

export const fetchArticles = async ({
  categories,
  author,
  tags,
  types,
  page,
  pageSize,
  sortedByClicks,
}: ArticleProps) => {
  const apiUrl = process.env.REACT_APP_STRAPI_URL + "/api/articles";
  const params: ArticleProps = {
    categories,
    author,
    tags,
    types,
    sortedByClicks,
  };

  const hasParams = Object.values(params).some((value) => value !== undefined);

  if (page !== undefined && pageSize !== undefined) {
    if (hasParams) {
      params.page = page;
      params.pageSize = pageSize;
    } else {
      params["pagination[page]"] = page;
      params["pagination[pageSize]"] = pageSize;
    }
  }

  console.log("Params are ! " + JSON.stringify(params));

  const { data } = await axios.get(hasParams ? `${apiUrl}/params` : apiUrl, {
    params,
  });

  const transformedArray: fetchedArticleProps[] | [] = hasParams
    ? data
    : data.data;

  console.log("called");
  return transformedArray;
};

export const incrementArticleClicks = async (slug: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_STRAPI_URL}/api/articles/${slug}/increment-clicks`
    );
    console.log("Article clicks incremented", response.data);
  } catch (error) {
    console.error("Error incrementing article clicks", error);
  }
};


export const countArticles = async ({
  categories,
  author,
  tags,
  types
}: ArticleProps) => {
  const apiUrl = process.env.REACT_APP_STRAPI_URL + "/api/articles/countparams";
  const params: ArticleProps = { categories, author, tags, types };


  const { data } = await axios.get(apiUrl, {
    params,
  });

  return data;
};

 export const fetchArticlesByIds = async (
   articleIds: any,
   page?: number,
   pageSize?: number,
   type?: "News" | "Research" | "Ecosystems" | "Reports"
 ) => {
   try {
     const idsParam = articleIds.join(",");
     const response = await axios.get(
       `${process.env.REACT_APP_STRAPI_URL}/api/articles/populate-fuzzy?articleIds=${idsParam}&page=${page}&pageSize=${pageSize}&type=${type}`
     );
     return response.data;
   } catch (error) {
     console.error("Error fetching articles: ", error);
   }
 };

export const searchArticles = async (
  searchQuery: string,
  page?: number,
  pageSize?: number,
  type?: "Research" | "News" | "Ecosystems" | "Reports"
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_STRAPI_URL}/api/fuzzy-search/search`,
      {
        params: {
          query: searchQuery,
        },
      }
    );

    const articles = response.data;

    if (!articles.articles || articles.articles.length === 0) {
      return [];
    }

    const articleIds = articles.articles.map((article: fetchedArticleProps) =>
      Number(article.id)
    );

    return fetchArticlesByIds(articleIds, page, pageSize, type);
  } catch (error) {
    console.error("Error fetching fuzzy search results:", error);
    throw error;
  }
};


export const fetchArticleBySlug = async (slug: string) => {
  const url = `${process.env.REACT_APP_STRAPI_URL}/api/articles?publicationState=live&filters[slug][$eq]=${slug}&populate[author][populate][0]=username&populate[author][populate][1]=profilePicture`;
  try {
    const response = await axios.get(url);
    return response.data?.data;
  } catch (error) {
    console.error("Fetching article failed:", error);
    return null;
  }
};

export const getCountOfArticles = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_STRAPI_URL}/api/articles/count`
    );
    return response.data; 
  } catch (error) {
    console.error("Error fetching articles count:", error);
  }
};

export const fetchLikesBookmarks = async (articleId: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_STRAPI_URL}/api/articles/${articleId}/likesbookmarks`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null; 
    }
};

export const toggleBookmark = async (articleId: string) => {

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_STRAPI_URL}/api/articles/toggle-bookmark`,
      { articleId }
    );
    
  } catch (error) {
    console.error("Error adding article to bookmarks:", error);
  }
};

export const toggleLike = async (articleId: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_STRAPI_URL}/api/articles/toggle-like`,
      { articleId }
    );
    console.log(response.data);
  } catch (error) {
    console.error("Error adding article to likes:", error);
  }
};