import { useState } from "react";
import SearchBar from "../components/layout/terminal-layout/search-bar";
import TagBar from "../components/layout/terminal-layout/categories-section/cats-bar";
import { researchTags } from "../constsants";
import { ArticleProps } from "../types/types";
import SearchResults from "../components/layout/terminal-layout/search-results";
import ResearchResults from "../components/layout/terminal-layout/research-results";
import MobileSwitch from "../components/layout/terminal-layout/mobile-switch";

const MarketReports = () => {
  const [searchInput, setSearchInput] = useState("");
  const [activeCategory, setActiveCategory] = useState("All");
    const [sortByClicks, setSortByClicks] = useState(false);

  let filterParams: ArticleProps = {
    types: "Reports",
    pageSize: 10,
    sortedByClicks: sortByClicks,
  };

  return (
    <div className="flex flex-col min-h-[650px] h-full">
      <SearchBar
        type="Reports"
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        sortByClicks={sortByClicks}
        setSortByClicks={setSortByClicks}
      />

      <MobileSwitch />

      {searchInput.length > 0 ? (
        <SearchResults searchQuery={searchInput} pageSize={10} type="Reports" />
      ) : (
        <ResearchResults filterParams={filterParams} />
      )}
    </div>
  );
};

export default MarketReports;
