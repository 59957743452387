import React, {
  useState,
  useRef,
  useEffect,
  FormEvent,
  ChangeEvent,
} from "react";
import {
  fetchUserData,
  updateUserName,
  updateUserProfile,
  uploadFile,
} from "../api/user";
import { useAuth } from "../components/layout/authContext";
import { fetchedArticleProps } from "../types/types";
import ArticlesGrid from "../components/layout/terminal-layout/articles-section/articles-grid";
import { fetchArticlesByIds } from "../api/articles";
import { useNavigate } from "react-router-dom";

const Account = () => {
  const [editAccount, setEditAccount] = useState(false);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [newUsername, setNewUsername] = useState("");
  const [joinedOn, setJoinedOn] = useState("");
  const [bookmarks, setBookmarks] = useState<fetchedArticleProps[]>([]);
  const [profilePicture, setProfilePicture] = useState<string>("");

  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const { userId, handleLogout, handleDeleteAccount, isLoading } = useAuth();

  const handleFetchUserData = async () => {
    if (userId) {
      fetchUserData(userId?.toString()).then((res) => {
        setProfilePicture(`${res.profilePicture.url}`);
        setEmail(res.email);
        setUsername(res.username);
        setJoinedOn(res.createdAt);
        if (res.bookmarked.length > 0) {
          const fetchAndSetArticles = async () => {
            const fetchedArticles = await fetchArticlesByIds(
              res.bookmarked.map((e: any) => e.id),
              1,
              999,
              "Research"
            );

            if (fetchedArticles) {
              setBookmarks(fetchedArticles);
            }
          };

          fetchAndSetArticles();
        }
      });
    } else {
      if (!isLoading) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    handleFetchUserData();
  }, [userId, editAccount, isLoading]);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleUpload = async () => {
    if (!selectedFile || !userId) {
      console.log("No file selected");
      return;
    }

    try {
      const uploadResponse = await uploadFile(selectedFile);
      const fileData = uploadResponse.data[0];
      console.log("File uploaded successfully ", JSON.stringify(fileData));
      await updateUserProfile(fileData.id, userId).then(() => setEditAccount(false))
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  const logoutHandler = () => {
    handleLogout();
    navigate("/");
  };

  const deleteHandler = () => {
    handleDeleteAccount();
    navigate("/");
  };

  const handleChangeName = () => {
    if (userId) {
      updateUserName(newUsername, userId);
      setUsername(newUsername);
      setNewUsername("");
    }
  };

  return (
    <div className="px-[20px] pt-[20px] xs:pt-[40px] max-w-screen overflow-x-hidden">
      {editAccount ? (
        <>
          <div className="w-full bg-customGrey rounded-lg py-[15px] xs:py-[40px] px-[15px] xs:px-[32px] xs:mb-[40px]">
            <div className="flex flex-row justify-between pb-[36px]">
              <div className="AccountSettings text-neutral-50 text-[20px] xs:text-[32px] font-semibold leading-10">
                Account Settings
              </div>
              <div className="SaveCancelContainer flex flex-row gap-4">
                <div
                  className="EditSettingsButton flex flex-row justify-center items-center px-3 xs:w-[132px] h-9 xs:h-10 rounded-[200px] bg-customDark cursor-pointer text-customTextGrey"
                  onClick={() => setEditAccount(false)}
                >
                  Go Back
                </div>
              </div>
            </div>
            <div className="NameAndPhoto text-white text-xl font-medium leading-tight mb-[12px]">
              Name and photo
            </div>
            <div className="UpdateYourPersonalInformation text-slate-400 text-sm font-normal leading-none mb-[32px]">
              Update your personal information
            </div>
            <div className="profilePictureContainer flex flex-row gap-[20px] mb-[54px]">
              <img
                className="ProfilePicture w-[70px] xs:w-[100px] h-[70px] xs:h-[100px] rounded-full bg-white"
                src={previewUrl ? previewUrl : profilePicture}
                alt="profile"
              />
              <div
                onClick={triggerFileInput}
                className="cursor-pointer flex flex-row justify-center items-center w-[70px] xs:w-[100px] h-[70px] xs:h-[100px] rounded-full border border-red-600"
              >
                <img
                  alt="upload"
                  src="/assets/images/camera.png"
                  className="w-7 h-7"
                />
              </div>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: "none" }}
                accept="image/*"
              />

              {previewUrl && (
                <button
                  type="button"
                  onClick={handleUpload}
                  className="hover:text-customRed"
                >
                  Upload
                </button>
              )}
            </div>
            <div className=" flex flex-col xl:flex-row xs:gap-[33px] border-b border-slate-400 pb-4">
              <div className=" w-[100%] md:w-[528px] h-20 flex-col justify-start items-start gap-4 inline-flex">
                <div className=" text-white text-base font-normal leading-none">
                  Name
                </div>
                <div className=" w-[100%] md:w-[528px] h-12 flex flex-row">
                  <div className=" w-[100%] max-w-[250px] xs:max-w-none md:w-[528px] h-12 rounded-[28px] text-white text-base font-normal leading-none flex items-center border border-slate-400 border-opacity-20 pl-[24px]">
                    <input
                      placeholder={username}
                      value={newUsername}
                      onChange={(e) => setNewUsername(e.target.value)}
                      className="w-full outline-none bg-transparent"
                    />
                  </div>
                  {newUsername.length > 0 && (
                    <div
                      className="ml-4 flex flex-row justify-center items-center bg-customRed w-[132px] h-12 rounded-[200px] cursor-pointer text-white"
                      onClick={() => handleChangeName()}
                    >
                      Save
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="ContactInfoContainer flex flex-col py-[40px] border-b border-slate-400 max-w-[1090px]">
                <div className="Header w-[300px] md:w-[376px] h-12 flex flex-col justify-start items-start gap-3 mb-[32px]">
                  <div className="ContactInfo text-neutral-50 text-xl font-medium font-['Roboto'] leading-tight">
                    Contact Info
                  </div>
                  <div className="text-slate-400 text-sm font-normal font-['Roboto'] leading-none">
                    Your data is safe with is, this will not be provided to
                    anyone else
                  </div>
                </div>
                <div className="flex flex-col xl:flex-row gap-[25px] mb-[32px]">
                  <div className="Frame1000011817 w-[100%] md:w-[528px] h-20 flex-col justify-start items-start gap-4 inline-flex">
                    <div className="Subtitle w-[72px] text-white text-base font-normal font-['Roboto'] leading-none">
                      Email
                    </div>
                    <div className="Input w-[100%] md:w-[528px] h-12">
                      <div className="Rectangle6168 flex items-center w-[100%] md:w-[528px] h-12 bg-zinc-900 rounded-[28px] shadow border border-red-600 pl-[24px]">
                        <input
                          className="flex-grow text-sm md:text-lg text-white bg-transparent outline-none"
                          ref={inputRef}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onClick={() => setEmail("")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ConnectedAccountsContainer flex flex-col gap-[23px]">
                    <div className="Subtitle text-white text-base font-normal font-['Roboto'] leading-none">
                      Connected accounts
                    </div>
                
                  </div> *
                </div>
                <div className=" w-[205px] h-10 justify-start items-start gap-[7px] flex flex-row">
                  <div className=" px-[31px] py-[11px] bg-red-600 rounded-[20px] justify-center items-center gap-2.5 flex cursor-pointer">
                    <div className=" text-center text-white text-sm font-bold font-['Roboto'] leading-[18px]">
                      Save
                    </div>
                  </div>
                  <div className="Cancel px-[31px] py-[11px] bg-slate-400 rounded-[20px] justify-center items-center gap-2.5 flex cursor-pointer">
                    <div className="Text text-center text-white text-sm font-medium font-['Roboto'] leading-[18px]">
                      Cancel
                    </div>
                  </div>
                </div>
              </div> */}
            {/* <div className="PasswordContainter py-[40px] border-b border-slate-400 max-w-[1090px]">
                <div className="Frame1000011936 w-[100%] xl:w-[906px] h-12 flex-col justify-start items-start gap-3 flex mb-[32px]">
                  <div className="Password text-neutral-50 text-xl font-medium font-['Roboto'] leading-tight">
                    Password
                  </div>
                  <div className="text-slate-400 text-sm font-normal font-['Roboto'] leading-none">
                    Note that if you signed in with a connected account, you are
                    using that account’s login information and we cannot change
                    or reset those passwords here.
                  </div>
                </div>
                <div className="mb-[24px]">
                  <div className="Subtitle text-white text-base font-normal font-['Roboto'] leading-none mb-[20px]">
                    Current Password
                  </div>
                  <div className="PasswordForm flex flex-row justify-between items-center w-[100%] xl:w-[528px] h-12 rounded-[28px] border border-slate-400 border-opacity-20 pl-[24px] pr-[16px]">
                    <img src="/assets/images/password-dots.png" />
                    <img src="/assets/images/eye-off.png" />
                  </div>
                </div>
                <div className="NewPasswordContainer flex flex-col xl:flex-row gap-[33px] mb-[32px]">
                  <div className="flex flex-col gap-[16px]">
                    <div className="Subtitle text-white text-base font-normal font-['Roboto'] leading-none">
                      New Password
                    </div>
                    <div className="Rectangle6168 flex flex-row justify-between items-center w-[100%] xl:w-[528px] h-12 bg-zinc-900 rounded-[28px] shadow border border-red-600 pl-[24px] pr-[16px]">
                      <input
                        className="flex-grow text-sm md:text-lg text-white bg-transparent outline-none"
                        ref={inputRef}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        onClick={() => setNewPassword("")}
                      />
                      <img src="/assets/images/eye.png" />
                    </div>
                  </div>
                  <div className="flex flex-col gap-[16px]">
                    <div className="Subtitle text-white text-base font-normal font-['Roboto'] leading-none">
                      Confirm New Password
                    </div>
                    <div className="Rectangle6168 flex flex-row justify-between items-center w-[100%] xl:w-[528px] h-12 rounded-[28px] border border-slate-400 border-opacity-20 pl-[24px] pr-[16px]">
                      <input
                        className="flex-grow text-sm md:text-lg text-white bg-transparent outline-none"
                        ref={inputRef}
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        onClick={() => setConfirmNewPassword("")}
                      />
                      <img src="/assets/images/eye-off.png" />
                    </div>
                  </div>
                </div>
                <div className="SaveCancelContainer w-[205px] h-10 justify-start items-start gap-[7px] flex flex-row">
                  <div className="Save px-[31px] py-[11px] bg-red-600 rounded-[20px] justify-center items-center gap-2.5 flex cursor-pointer">
                    <div className="Text text-center text-white text-sm font-bold font-['Roboto'] leading-[18px]">
                      Save
                    </div>
                  </div>
                  <div className="Cancel px-[31px] py-[11px] bg-slate-400 rounded-[20px] justify-center items-center gap-2.5 flex cursor-pointer">
                    <div className="Text text-center text-white text-sm font-medium font-['Roboto'] leading-[18px]">
                      Cancel
                    </div>
                  </div>
                </div>
              </div> */}
            <div className="flex flex-col py-[20px] xs:py-[40px] border-b border-slate-400">
              <div className=" w-[350px] flex-col justify-start items-start gap-y-4 xs:gap-8 flex pt-[10px] ">
                <div className="DeleteAccount flex-col justify-start items-start gap-3 flex">
                  <div className="DeleteMyAccount text-neutral-50 text-xl font-medium leading-tight">
                    Log out
                  </div>
                  {/* <div className="text-slate-400 text-sm font-normal font-['Roboto'] leading-none">
                    Do you want to downgrade instead? Manage Subscriptions
                  </div> */}
                </div>
                <div
                  onClick={() => logoutHandler()}
                  className=" px-6 py-[11px] cursor-pointer bg-neutral-700 text-slate-400 hover:bg-neutral-500 hover:text-white rounded-[20px] justify-center items-center gap-2.5 inline-flex"
                >
                  <p className=" text-center text-sm font-bold leading-[18px]">
                    Log out
                  </p>
                </div>
              </div>
            </div>
            <div className=" flex-col justify-start items-start gap-y-4 xs:gap-8 flex pt-[40px]">
              <div className="DeleteAccount flex-col justify-start items-start gap-3 flex">
                <div className="DeleteMyAccount text-neutral-50 text-xl font-medium leading-tight">
                  Delete my account
                </div>
                {/* <div className="text-slate-400 text-sm font-normal font-['Roboto'] leading-none">
                    Do you want to downgrade instead? Manage Subscriptions
                  </div> */}
              </div>
              <div
                onClick={() => deleteHandler()}
                className="Frame3 px-6 py-[11px] bg-customRed hover:bg-red-700 cursor-pointer rounded-[20px] justify-center items-center gap-2.5 inline-flex"
              >
                <div className="Text text-center text-white text-sm font-bold leading-[18px]">
                  Delete account
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="w-full  bg-customGrey rounded-lg py-[20px] xs:py-[40px] px-[20px] xs:px-[32px] xs:mb-[40px]">
            <div className="flex flex-row justify-between pb-[30px]">
              <div className="AccountSettings text-neutral-50 text-[20px] xs:text-[32px] font-semibold leading-10">
                Account Settings
              </div>
              <div
                className="EditSettingsButton flex flex-row justify-center items-center w-[132px] h-9 xs:h-10 rounded-[200px] cursor-pointer text-customTextGrey border border-customTextGrey"
                onClick={() => setEditAccount(true)}
              >
                Edit account
              </div>
            </div>
            <img
              className="ProfilePicture w-[70px] xs:w-[100px] h-[70px] xs:h-[100px] rounded-full bg-white mb-[15px] xs:mb-[32px]"
              src={profilePicture}
            />
            <div className=" text-neutral-50 text-xl font-medium leading-tight pb-[12px]">
              {username}
            </div>
            <div className=" text-slate-400 text-sm font-medium leading-none pb-[24px]">
              Joined{" "}
              {new Date(joinedOn).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}
            </div>
            {
              // TODO make dynamic list here
            }
            <div className="User Tags h-9 justify-start items-start gap-3 inline-flex">
              <div className=" xs:w-52 px-5 py-2 rounded-[28px] border border-white border-opacity-25 justify-center items-center gap-2 flex">
                <div className="Text text-white text-base font-normal leading-none">
                  {email}
                </div>
              </div>
            </div>
          </div>
          <div className="Bookmarks w-full bg-customGrey rounded-lg xs:py-[32px]">
            <div className="xs:ml-[32px] flex flex-row justify-center items-center w-[164px] h-[40.75px] text-customTextGrey xs:border border-customTextGrey rounded-[200px] mb-[16px]">
              <img
                src="/assets/images/bookmark.png"
                className="h-6 w-6 mr-[4px]"
              />
              <div className="text-lg font-semibold leading-none">
                Bookmarked
              </div>
            </div>

            {bookmarks && (
              <ArticlesGrid
                articles={bookmarks}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Account;
