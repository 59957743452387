import React, { useEffect, useState } from "react";
import { IMessage } from "../../../../types/types";
import Markdown from "react-markdown";
import "./markdown.css"

interface MessageProps {
  message: IMessage;
  picture?: string;
  isWaiting?: boolean;
}

const Message: React.FC<MessageProps> = ({ message, picture, isWaiting }) => {
  const [displayedText, setDisplayedText] = useState("");

   useEffect(() => {
     if (message.animateText) {
       let i = 0;
       const intervalId = setInterval(() => {
         setDisplayedText(message.content.slice(0, i));
         i++;
         if (i > message.content.length) clearInterval(intervalId);
       }, 5); // Adjust the delay here for the speed of the typewriter effect

       return () => clearInterval(intervalId);
     } else {
       // If not animating, display the full text immediately
       setDisplayedText(message.content);
     }
   }, [message, message.animateText]);
  
  return (
    <div
      className={`flex bg-neutral-700 flex-row items-start p-1 rounded-2xl flex-shrink-0 flex-grow-0 basis-auto max-w-[95%] ${
        message.isUser ? "justify-end " : "justify-start mr-[200px]"
      } m-2`}
    >
      {!message.isUser && (
        <img
          alt="logo"
          src="/assets/images/m6-icon.png"
          className={`h-[35px] w-[35px] mx-1   transition-all duration-500 ${
            isWaiting ? "animate-spin mx-3 my-2" : "mt-[6px]"
          }`}
        />
      )}
      {!isWaiting && (
        <div id="markdown" className={`inline-block p-3 rounded-lg mb-[-10px]`}>
          {displayedText.split("\n").map((text: string, index: number) => (
            <Markdown key={index} className="mb-[10px]">
              {text}
            </Markdown>
          ))}
        </div>
      )}

      {message.isUser && (
        <img
          alt="logo"
          src={picture}
          className={`h-[35px] rounded-full w-[35px] mt-[6px] mx-1 transition-all duration-500}`}
        />
      )}
    </div>
  );
};

export default Message;
