import React from "react";

const SubscribeModal = ({
  show,
  setShow,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  if (!show) {
    return null;
  }

  const handleBackdropClick = (
    e: any
  ) => {
    if (e.target.id === "modal-backdrop") {
      setShow(false);
    }
  };

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
      onClick={handleBackdropClick}
      id="modal-backdrop"
    >
      <div
        onClick={stopPropagation}
        className="bg-customArticleGrey z-50 mt-[40px] w-full max-w-[730px] rounded-[20px] px-4 py-[80px] flex flex-col items-center justify-center text-white gap-y-4"
        style={{
          backgroundImage:
            "url('/assets/images/article/article-tr.svg'), url('/assets/images/article/article-bl.svg')",
          backgroundPosition: "left top, right bottom",
          backgroundRepeat: "no-repeat, no-repeat",
        }}
      >
        <h1 className="text-3xl font-bold">Subscribe on M6 Labs</h1>
        <p className="w-full max-w-[600px] text-center">
          Providing a concise and objective overview of recent events in crypto
        </p>
        <div className="w-full max-w-[600px] flex justify-between flex-row items-center p-1 rounded-full border border-customRed">
          <input
            className="bg-customArticleGrey text-customTextGrey ml-4 outline-none w-full"
            placeholder="Enter Your Email"
          />
          <div
            className="bg-customRed rounded-full p-3 px-5"
            onClick={() => setShow(false)}
          >
            <p className="font-bold">Subscribe</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribeModal;
