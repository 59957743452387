import React from 'react';
import { useFetchArticles } from '../../../api/hooks';
import { trimAndAddEllipsis } from '../../../helpers/trimAndAddEllipis';
import { useNavigate } from 'react-router-dom';

interface MostPopularItemProps {
    image?: string
    title: string 
    date: string
    slug: string
}

const MostPopularItem = ({ image, title, date, slug }: MostPopularItemProps) => {
    const nav = useNavigate();
    const imgUrl = `${image}`;
  return (
    <div
      className="flex mb-[5px] cursor-pointer"
      onClick={() => nav(`/p/${slug}`)}
    >
      <div className="flex flex-col ml-[5px]">
        <h3 className="text-black font-semibold text-base"> {trimAndAddEllipsis(title, 45)}</h3>
        <p className="text-[#121212AD] text-[14px] p-0">
          {new Date(date).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
          })}
        </p>
      </div>
    </div>
  );
};


const FooterMostPopular = () => {
 const {
   data: researchArticles,
 } = useFetchArticles({
   types: "News",
   page: 1,
   pageSize: 3,
 });

    return (
      <div>
        {researchArticles?.map((item) => (
          <MostPopularItem
            key={item.id}
            image={item.thumbnailUrl.url}
            title={item.title}
            date={item.publishDate}
            slug={item.slug}
          />
        ))}
      </div>
    );
};

export default FooterMostPopular;