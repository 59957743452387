import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsFillLightbulbFill } from "react-icons/bs";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaBrain } from "react-icons/fa";

interface SideNavProps {
  selectedTab: string;
  setSelectedTab: (arg0: string) => void;
}

interface SideNavItemProps extends SideNavProps {
  name: string;
  icon: string;
  route?: string;
  inActive?: boolean;
}

const SideNavItem = ({
  selectedTab,
  setSelectedTab,
  name,
  icon,
  inActive,
  route
}: SideNavItemProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
     setSelectedTab(inActive ? selectedTab : name);
    if (!inActive && route) {
      navigate(route);
    }
  };

  return (
    <div
      className={`h-6 flex flex-row justify-start items-center mb-[40px] cursor-pointer`}
      onClick={() => handleClick()}
    >
      <img
        alt="img"
        className="w-[24x] h-[24px]"
        src={`/assets/images/${icon}${
          selectedTab === name && !inActive ? "red" : "grey"
        }.png`}
      />
      <div
        className={`ml-[16px] text-${
          selectedTab === name && !inActive
            ? "red-600"
            : inActive
            ? "slate-600"
            : "slate-400 hover:text-white duration-500"
        } text-lg font-semibold leading-none`}
      >
        <p>{name}</p>
      </div>
    </div>
  );
};

const SideNav = (props: SideNavProps) => {
  const { selectedTab, setSelectedTab } = props;
    const navigate = useNavigate();

  const handleRouteChange = (route: string, name: string) => {
      setSelectedTab(name);
      navigate(route);
  }

  return (
    <div className="flex-col relative min-w-[211px] max-h-[300px] bg-customGrey rounded-lg pl-[16px] py-[32px] hidden xl:flex">
      <SideNavItem
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        name="Research"
        icon="clipboard_"
        route="/"
      />

      <div
        className={`h-6 z-20 flex flex-row justify-start items-center mb-[40px] cursor-pointer `}
        onClick={() => handleRouteChange("/assistant", "AI Assistant")}
      >
        <FaBrain
          className={` text-[22px] w-[26px] ${
            selectedTab === "AI Assistant" ? "text-customRed" : "text-slate-400"
          }`}
        />
        <div
          className={`ml-[16px] ${
            selectedTab === "AI Assistant" ? "text-customRed" : "text-slate-400"
          } hover:text-white duration-500"
           text-lg font-semibold leading-none`}
        >
          <p>AI Insights</p>
        </div>
      </div>

      <SideNavItem
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        name="Market Reports"
        route="/market-reports"
        icon="doughnut-chart_"
      />

      <div
        className={`h-6 z-20 flex flex-row justify-start items-center mb-[40px] cursor-pointer`}
        onClick={() =>
          window.open(
            "https://cryptopragmatist.com/subscribe?utm_source=M6Labs&utm_medium=Website&utm_campaign=HeaderButton",
            "_blank"
          )
        }
      >
        <img
          alt="img"
          className="w-[24x] h-[24px]"
          src={`/assets/images/news_grey.png`}
        />
        <div
          className={`ml-[16px] text-slate-400 hover:text-white duration-500"
           text-lg font-semibold leading-none`}
        >
          <p>Newsletter</p>
        </div>
      </div>

      {/* <div className="flex flex-row h-6 mb-[40px] justify-center items-center gap-[4px]">
        <div className=" text-slate-600 text-lg font-semibold leading-none">
          Coming Soon
        </div>
        <img src="/assets/images/coming_soon_icon.png" className="h-[2px]" />
      </div>

      <SideNavItem
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        name="Data"
        icon="doughnut-chart_"
        inActive
      />

      <SideNavItem
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        name="NFTs"
        icon="hexagon_"
        inActive
      />

      <SideNavItem
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        name="Tools"
        icon="tools_"
        inActive
      />

      <SideNavItem
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        name="NewsBot"
        icon="news_"
        inActive
      /> */}
    </div>
  );
};

export default SideNav;
