import { OpenAI } from 'openai';

export const initializeAssistants = async () => {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  // async function deleteAllAssistants() {
  //   try {
  //     const assistantsList = await openai.beta.assistants.list();
  //     for (const assistant of assistantsList.data) {
  //       await openai.beta.assistants.del(assistant.id);
  //       console.log(`Deleted assistant with ID: ${assistant.id}`);
  //     }

  //     console.log("All assistants have been deleted.");
  //      deleteAllAssistants();
  //   } catch (error) {
  //     console.error("Error occurred:", error);
  //   }
  // } 

  //   const researcherAssistant = await openai.beta.assistants.create({
  //     name: "Max",
  //     instructions: `Your name is Max. You have a great sense of humor, with a touch of sarcasm. When someone asks you to tell the joke it has to be about crypto. You are a professional crypto research assistant specializing in real-time crypto token research.
  // You always start with Coingecko API for any query, followed by additional user-requested information.
  // If user will ask you about specific token or coin, you have to furst get data about it from getTokenData function.
  // If user will ask you to get a list of new tokens or coins, you have to get data about it from getNewTokens function.
  //  If user will ask you about ohlc, you have to get data about it from getOhlc function and create a best analysis you can. If user will not provide amount of days, call the function for 7 days.
  // If you cant find any info or get any kind of error, return "Sorry, I couldn't retrieve the information you requested. Please ensure the token symbol or name is correct and try again. If you need assistance with a specific query, feel free to ask!" as the response.
  // You have to provide up-to-date insights including FDV, volume, market cap, USD price, socials, and more, ensuring to include market cap and 24-hour volume.
  // You display data starting with "Project Description," followed by Market Information, Website, Socials, Docs, Audit Info, Developer info and other insights.
  // You are knowledgeable about the cryptocurrency market and adept at presenting real-time data with a direct and concise communication style.
  // Your responsibilities include:
  // Expand Domain Knowledge: You continuously update and deepen your knowledge about the latest developments and trends.
  // Simplify Complex Concepts: You break down complex ideas to make them understandable for everyone.
  // Analyze Real-World Applications: You provide analysis of case studies or real-world examples.
  // Address Ethical and Social Implications: You integrate discussions on ethical considerations and societal impacts.
  // Recommend Tools and Resources: You suggest appropriate tools and learning materials tailored to different expertise levels.
  // Industry-Specific Insights: You deliver insights applicable to practical, industry-specific contexts.
  // Forecast Future Trends: You offer predictions or analyses on future developments and trends.
  // Interactive Learning and Engagement: You create interactive learning experiences to engage users.
  // Guide Project Development: You provide guidance on conceptualizing, planning, and executing relevant projects.
  // Ask for Clarifying Questions: You ask clarifying questions to ensure accurate and tailored responses.
  // Use Custom Knowledge Base: You always refer to your knowledge base first before responding.`,
  //     tools: [
  //       { type: "code_interpreter" },
  //       {
  //         type: "function",
  //         function: {
  //           name: "getTokenData",
  //           parameters: {
  //             type: "object",
  //             properties: {
  //               query: {
  //                 type: "string",
  //                 description: "Name of given token",
  //               },
  //               unit: {
  //                 type: "string",
  //               },
  //             },
  //             required: ["query"],
  //           },
  //           description:
  //             "Get data for a cryptocurrency token by given query. Returns an object with the token data.",
  //         },
  //       },
  //       {
  //         type: "function",
  //         function: {
  //           name: "getOhlc",
  //           parameters: {
  //             type: "object",
  //             properties: {
  //               query: {
  //                 type: "string",
  //                 description: "Name of given token",
  //               },
  //               days: {
  //                 type: "string",
  //                 description: "Amount of days to fetch data for",
  //               },
  //               unit: {
  //                 type: "string",
  //               },
  //             },
  //             required: ["query", "days"],
  //           },
  //           description: "Get ohlc for a cryptocurrency token by given name.",
  //         },
  //       },
  //       {
  //         type: "function",
  //         function: {
  //           name: "getNewTokens",
  //           parameters: {
  //             type: "object",
  //             properties: {
  //               query: {
  //                 type: "string",
  //                 description: "Get a list of new tokens or coins",
  //               },
  //             },
  //             required: [],
  //           },
  //           description:
  //             "Get data for a cryptocurrency token by given query. Returns an object with the token data.",
  //         },
  //       },
  //     ],
  //     model: "gpt-4-1106-preview",
  //   });

  const researcherAssistant = await openai.beta.assistants.retrieve(
    "asst_6zFEP7CJ5qdkwZJwgWubnUPj"
  );

  const storedThread = localStorage.getItem("threadId");
  let thread;

  if (storedThread) {
    thread = await openai.beta.threads.retrieve(storedThread);
    // const runs = await openai.beta.threads.runs.list(thread.id);
  } else {
    thread = await openai.beta.threads.create();
    localStorage.setItem("threadId", thread.id);
  }

  return { researcherAssistant, thread, openai };
};
