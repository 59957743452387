import React from "react";
import { ArticleProps } from "../../../types/types";
import {
  useArticlesInfiniteQuery,
  useIntersectionObserver,
} from "../../../api/hooks";
import ArticlesGrid from "./articles-section/articles-grid";
import ItemLoaderGroup from "./articles-section/item-loader";

const ResearchResults = ({ filterParams }: { filterParams: ArticleProps }) => {
  const { data, fetchNextPage, hasNextPage, status } =
    useArticlesInfiniteQuery(filterParams);

  const loadMoreRef = useIntersectionObserver(hasNextPage, fetchNextPage);

  return (
    <div className="max-h-[650px] pb-[20px] overflow-y-auto white-scrollbar">
      {status === "loading" ? (
        <ItemLoaderGroup />
      ) : (
        data?.pages.map((page, index) => (
          <ArticlesGrid articles={page} key={index} />
        ))
      )}
      <div ref={loadMoreRef} className="h-1 " />
    </div>
  );
};

export default ResearchResults;
