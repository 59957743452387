import React from "react";
import { ArticleProps } from "../../../types/types";
import {
  useIntersectionObserver,
  useSearchArticlesInfiniteQuery,
} from "../../../api/hooks";
import ArticlesGrid from "./articles-section/articles-grid";
import ItemLoaderGroup from "./articles-section/item-loader";
import { HiBars3BottomLeft } from "react-icons/hi2";

const SearchResults = ({
  searchQuery,
  pageSize,
  type,
}: {
  searchQuery: string;
  pageSize: number;
  type: "News" | "Research" | "Ecosystems" | "Reports";
}) => {
  const { data, fetchNextPage, hasNextPage, status } =
    useSearchArticlesInfiniteQuery(searchQuery, pageSize, type);

  const loadMoreRef = useIntersectionObserver(hasNextPage, fetchNextPage);

  return (
    <div className="max-h-[650px] overflow-y-auto white-scrollbar pb-[20px]">
      {data?.pages[0].length !== 0 && searchQuery.length > 0 && (
        <div className="pt-4 ml-10 flex flex-row text-customTextGrey text-base">
          <p className="p-0  mr-1">Search results for</p>"
          <p className="text-customRed">{searchQuery}</p>"
        </div>
      )}
      {status === "loading" ? (
        <ItemLoaderGroup />
      ) : data?.pages[0].length === 0 ? (
        <div className="h-[500px] w-full flex items-center justify-center flex-col gap-y-3">
          <HiBars3BottomLeft className="text-customTextGrey text-[45px]" />
          <p className="p-0 text-base text-customTextGrey mb-4">
            No research found matching the search criteria
          </p>
        </div>
      ) : (
        data?.pages.map((page, index) => (
          <ArticlesGrid articles={page} key={index} />
        ))
      )}
      <div ref={loadMoreRef} className="h-1 " />
    </div>
  );
};

export default SearchResults;
