import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link, useNavigate, useLocation } from 'react-router-dom'
import MobileNav from "./mobileNav";
import { useAuth } from "./authContext";
import { fetchUserImage } from "../../api/user";

type HeaderPropsT = {
  setShowModal: (arg: boolean) => void
}
const Header = (props: HeaderPropsT) => {
  const {setShowModal} = props
    const location = useLocation();

    const navigate = useNavigate();
 const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
 const [profilePicture, setProfilePicture] = useState<string>(
   "/assets/images/user.png"
 );
 const { userId, isLoading} = useAuth();

 useEffect(() => {  
  if (userId) {
fetchUserImage(userId).then((res) => {
  if (res.profilePicture) {
setProfilePicture(`${res.profilePicture.url}`);
  }
});
  }
    
  }, [userId]);

  return (
    <div className="w-full fixed xs:relative z-30 shadow-2xl bg-customGrey flex justify-between items-center h-[50px] xs:h-[65px] py-1 xs:py-2 px-2 xs:px-4 md:px-6 ">
      <Link className="cursor-pointer" to="/">
        <div className="flex flex-row text-[24px] font-bold items-center ">
          <img
            alt="logo"
            src="/assets/images/m6-icon.png"
            className={`h-[35px] xs:h-[45px] w-[35px] xs:w-[45px] xs:ml-[16px] hover:animate-spin ${
              isLoading && "animate-spin"
            }  transition-all duration-500}`}
          />
          <h1 className="text-customRed ml-2 xs:ml-3 text-xl xs:text-2xl">
            M6
          </h1>
          <h1 className="text-white ml-1 xs:ml-2 text-xl xs:text-2xl">LABS</h1>
        </div>
      </Link>

      <div className="flex flex-row space-x-3 xs:space-x-8 mr-2 xs:mr-4 items-center text-white text-[16px] leading-[25.20px] text-base font-normal">
        <Link
          className="cursor-pointer hover:underline hidden xs:flex"
          to="/about"
        >
          About Us
        </Link>
        <Link
          className="leading-[25.20px] hover:underline cursor-pointer hidden xs:flex"
          to="/services"
        >
          Partner with us
        </Link>
        <button
          className=" bg-customRed transition-transform duration-500 hover:scale-105 py-[6px] text-white px-5 rounded-full hidden xs:flex"
          onClick={() =>
            window.open(
              "https://cryptopragmatist.com/subscribe?utm_source=M6Labs&utm_medium=Website&utm_campaign=HeaderButton",
              "_blank"
            )
          }
        >
          Subscribe
        </button>
        {userId ? (
          <div
            className="p-[2px] rounded-full border border-[#353C4F] cursor-pointer"
            onClick={() => navigate("/account")}
          >
            <img
              src={profilePicture}
              alt="/assets/images/m6-icon.png"
              className="w-[30px] xs:w-[40px] h-[30px] xs:h-[40px] rounded-full bg-white"
            />{" "}
          </div>
        ) : (
          <button
            className="py-[6px] transition-transform duration-500 hover:scale-105 text-white border border-white px-5 rounded-full hidden md:flex cursor-pointer"
            onClick={() => navigate("/login")}
          >
            Login
          </button>
        )}

        <FaBars
          className="text-[24px] xl:hidden cursor-pointer"
          onClick={() => {
            setIsMobileModalOpen(true);
          }}
        />

        <MobileNav
          isOpen={isMobileModalOpen}
          setIsMobileOpen={setIsMobileModalOpen}
          setShowModal={setShowModal}
          userId={userId}
        />
      </div>
    </div>
  );
};

export default Header;
