export type ArticleProps = {
  title?: string;
  subTitle?: string;
  categories?: string;
  author?: string;
  tags?: string;
  types?: "News" | "Research" | "Ecosystems" | "Reports";
  page?: number;
  content?: string;
  pageSize?: number;
  sortedByClicks?: boolean;
  "pagination[page]"?: number;
  "pagination[pageSize]"?: number;
  "filters[publishedAt][$ne]"?: any; 
};

export type fetchedArticleProps = {
  id: string;
  title: string;
  subtitle: string;
  content: string;
  slug: string;
  keywords: string;
  createdAt: string;
  publishedAt: string;
  publishDate: string;
  updatedAt: string;
  categiries?: {
    data: Array<{
      id: number;
      attributes: {
        name: string;
      };
    }>;
  };
  author: {
    data: {id: number;
    attributes: {
      username: string;
    };}
  };
  thumbnailUrl: {
    data: {id: number;
    attributes?: {
      url: string;
    };}
    url?: string
  };
};

export interface IComment {
  id: string;
  from_admin: boolean;
  createdAt: string;
  content: string;
  author: IAuthor | null;
  subcomments?: ISubcomment[]
}

export interface IAuthor {
  username: string;
  email: string;
  id: string;
  profilePicture?: string
}

export interface ISubcomment {
  id: string;
  from_admin: boolean;
  createdAt: string;
  content: string;
  author: IAuthor | null;
}

export class IMessage {
    isUser: boolean;
    content: string;
    animateText: boolean;

    constructor(isUser: boolean, content: string, animateText: boolean = false) {
        this.isUser = isUser;
        this.content = content;
        this.animateText = animateText;
    }
}


