import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../components/layout/authContext";
import { useState, useRef } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import { handleForgotPassword } from "../api/user";

interface LoginData {
  email: string;
  password: string;
}

interface SignupData extends LoginData {
  username: string;
}

const Login = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isUsernameValid, setIsUsernameValid] = useState(true);

  const [loginCredentials, setLoginCredentials] = useState<LoginData>({
    email: "",
    password: "",
  });

  const [signupCredentials, setSignupCredentials] = useState<SignupData>({
    username: "",
    email: "",
    password: "",
  });

    const validateEmail = (email: string) => {
      const isValid = /\S+@\S+\.\S+/.test(email);
      setIsEmailValid(isValid);
      return isValid;
    };

    const validatePassword = (password: string) => {
      const isValid = password.length >= 6;
      setIsPasswordValid(isValid);
      return isValid;
    };

    const validateUsername = (username: string) => {
      const isValid = username.length >= 2;
      setIsUsernameValid(isValid);
      return isValid;
    };

  const isFormValid = () => {
    const emailValid = validateEmail(
      isSignUp ? signupCredentials.email : loginCredentials.email
    );
    const passwordValid = validatePassword(
      isSignUp ? signupCredentials.password : loginCredentials.password
    );
    const usernameValid = isSignUp
      ? validateUsername(signupCredentials.username)
      : true;
    return emailValid && passwordValid && usernameValid;
  };


  const { setAuthToken, setUserId } = useAuth();
  const navigate = useNavigate();

  const handleLogin = () => {
    if (isFormValid()) {
      setIsLoading(true);
      axios
        .post(`${process.env.REACT_APP_STRAPI_URL}/api/auth/local`, {
          identifier: loginCredentials.email,
          password: loginCredentials.password,
        })
        .then((response) => {
          console.log("Login successful!", response);
          if (response.data.jwt && response.data.user.id) {
            setAuthToken(response.data.jwt);
            setUserId(response.data.user.id);
            setIsLoading(false);
            navigate("/");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Login error", error);
        });
    }
  };

  const handleRegistration = () => {
    if (isFormValid()) {
      setIsLoading(true);
      setIsEmailValid(true);
      setIsPasswordValid(true);
      setIsUsernameValid(true);
      axios
        .post(
          `${process.env.REACT_APP_STRAPI_URL}/api/auth/local/register`,
          signupCredentials
        )
        .then((response) => {
          console.log("Registration successful!", response);
          if (response.data.jwt && response.data.user.id) {
            setAuthToken(response.data.jwt);
            setUserId(response.data.user.id);
            setIsLoading(false);
            navigate("/");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Registration error", error);
        });
    }
  };

   const mutation = useMutation(handleForgotPassword, {
     onSuccess: (data) => {
       console.log("Forgot password response:", data);
       navigate("/passreset");
     },
     onError: (error) => {
       console.error("Forgot password error:", error);
     },
   });

    const onForgotPass = () => {
      console.log("action")
      if (loginCredentials.email.length > 3) {
        console.log("true")
    mutation.mutate(loginCredentials.email);
      }
      else {
        setIsEmailValid(false);
        alert("Please enter a valid email address")
      }
    };

  return (
    <div className=" flex flex-col justify-center items-center xs:pt-[30px]">
      <div className=" flex flex-col w-full xl:w-[1030px]">
        <div className=" bg-customDark flex flex-col gap-[12px]">
          <div className="flex flex-row text-[24px] font-bold items-center justify-center">
            <img
              alt="logo"
              src="/assets/images/m6-icon.png"
              className={`h-[35px] xs:h-[95px] w-[35px] xs:w-[95px] xs:ml-[16px] ${
                isLoading && "animate-spin"
              }`}
            />
            <h1 className="text-customRed ml-2 xs:ml-3 text-xl xs:text-2xl">
              M6
            </h1>
            <h1 className="text-white ml-1 xs:ml-2 text-xl xs:text-2xl">
              LABS
            </h1>
          </div>
          <div className="Title text-center text-white text-[30px] xs:text-[52px] font-bold leading-[52px] mb-[48px]">
            {isSignUp ? "Sign up for your Account" : "Sign in to your Account"}
          </div>
        </div>
        <div
          className="flex flex-row justify-end pb-[30px] bg-customGrey mx-4 rounded-2xl"
          style={{
            backgroundImage:
              "url('/assets/images/login_bottom.svg'), url('/assets/images/login_top.svg')",
            backgroundPosition: "left top, left bottom",
            backgroundRepeat: "no-repeat, no-repeat",
          }}
        >
          <div className="flex flex-col items-start xl:items-end pr-[64px] pt-[64px] w-[100%] lg:w-[50%] min-w-[300px] px-4">
            {isSignUp && (
              <div className=" mb-[32px] w-full min-w-[300px]">
                <div className="Subtitle text-white text-base font-normal leading-none mb-[16px]">
                  Full Name
                </div>
                <div
                  className={`PasswordForm flex flex-row justify-between items-center w-full h-12 min-w-[300px] rounded-[28px] border border-opacity-20 pl-[24px] pr-[16px] ${
                    isUsernameValid ? "border-slate-400" : "border-customRed"
                  }`}
                >
                  <input
                    className="flex-grow w-full text-sm md:text-lg text-white bg-transparent outline-none"
                    ref={inputRef}
                    value={signupCredentials.username}
                    onChange={(e) =>
                      setSignupCredentials({
                        ...signupCredentials,
                        username: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            )}
            <div className="EmailInput mb-[32px] w-full min-w-[300px]">
              <div className="Subtitle text-white text-base font-normal leading-none mb-[16px]">
                Email Address
              </div>
              <div 
                className={`w-full min-w-[300px] flex flex-row justify-between items-center h-12 rounded-[28px] border border-opacity-20 pl-[24px] pr-[16px] ${
                  isEmailValid ? "border-slate-400 border-opacity-20" : "border-customRed"
                }`}
              >
                <input
                  className="flex-grow w-full min-w-[300px] text-sm md:text-lg text-white bg-transparent outline-none"
                  ref={inputRef}
                  value={
                    isSignUp ? signupCredentials.email : loginCredentials.email
                  }
                  onChange={(e) =>
                    isSignUp
                      ? setSignupCredentials({
                          ...signupCredentials,
                          email: e.target.value,
                        })
                      : setLoginCredentials({
                          ...loginCredentials,
                          email: e.target.value,
                        })
                  }
                />
              </div>
            </div>
            <div className="w-full min-w-[300px] mb-[32px]">
              <div className="Subtitle text-white text-base font-normal leading-none mb-[16px]">
                Password
              </div>
              <div
                className={`w-full min-w-[300px] flex flex-row justify-between items-center h-12 rounded-[28px] border border-opacity-20 pl-[24px] pr-[16px] ${
                  isPasswordValid ? "border-slate-400" : "boreder-customRed"
                }`}
              >
                <input
                  type="password"
                  className="flex-grow text-sm md:text-lg text-white bg-transparent outline-none w-full min-w-[300px]"
                  ref={inputRef}
                  value={
                    isSignUp
                      ? signupCredentials.password
                      : loginCredentials.password
                  }
                  onChange={(e) =>
                    isSignUp
                      ? setSignupCredentials({
                          ...signupCredentials,
                          password: e.target.value,
                        })
                      : setLoginCredentials({
                          ...loginCredentials,
                          password: e.target.value,
                        })
                  }
                />
              </div>
            </div>
            {!isSignUp && (
              <div className="RememberMeForgotPassContainer  w-full h-5 justify-end items-center flex mb-[32px]">
                {/* <div className="justify-start items-center gap-3 flex">
                  <input
                    type="checkbox"
                    readOnly
                    checked={true}
                    className="w-5 h-5 rounded-md border border-white"
                  />
                  <div className="text-white text-base font-normal leading-none">
                    Remember me
                  </div>
                </div> */}
                <div
                  className="ForgetPassword self-end hover:underline text-customRed text-base font-normal leading-none cursor-pointer"
                  onClick={() => onForgotPass()}
                >
                  Forget Password?
                </div>
              </div>
            )}
            <div
              onClick={() => {
                isSignUp ? handleRegistration() : handleLogin();
              }}
              className={`w-full min-w-[300px] h-[52px] py-[17px] ${
                (
                  isSignUp
                    ? signupCredentials.email.length > 4 &&
                      signupCredentials.password.length > 5 &&
                      signupCredentials.username.length > 2
                    : loginCredentials.email.length > 3 &&
                      loginCredentials.password.length > 5
                )
                  ? isLoading
                    ? "animate-pulse"
                    : "bg-customRed"
                  : "bg-red-600 opacity-40"
              } rounded-[28px] justify-center items-center gap-2.5 flex mb-[32px] cursor-pointer`}
            >
              <div className="Text text-white text-xl font-bold leading-[18px]">
                {isSignUp ? "Sign up" : "Sign in"}
              </div>
            </div>
            <div className="w-full min-w-[300px] flex justify-center items-center text-white text-base font-normal leading-none mb-[36px]">
              {isSignUp ? "or sign up with" : "or sign in with"}
            </div>
            <div className="ThirdPartyButtons w-full min-w-[300px] h-11 justify-center items-center gap-3 inline-flex mb-[36px]">
              <Link
                className="h-11 px-8 py-[13px] rounded-[28px] border border-white border-opacity-25 justify-center items-center gap-2 flex flex-row cursor-pointer"
                to={`${process.env.REACT_APP_STRAPI_URL}/api/connect/google`}
              >
                <img
                  src="/assets/images/google.png"
                  className="w-4 h-4"
                  alt="google"
                />
                <div className="Text w-[53px] text-white text-base font-medium leading-[18px]">
                  Google
                </div>
              </Link>
            </div>
            <div className="flex flex-row w-full min-w-[300px]  h-[18px] justify-center items-center gap-4">
              <div className="DonTHaveAnAccount text-white text-lg font-normal leading-[18px]">
                {isSignUp
                  ? "Already have an Account?"
                  : "Don`t have an Account?"}
              </div>
              <div
                className=" text-red-600 text-lg font-normal underline leading-[18px] cursor-pointer"
                onClick={() => {
                  isSignUp
                    ? setSignupCredentials({
                        username: "",
                        email: "",
                        password: "",
                      })
                    : setLoginCredentials({
                        email: "",
                        password: "",
                      });
                  setIsSignUp(!isSignUp);
                }}
              >
                {isSignUp ? "Sign in" : "Sign up"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
