import React, { useState } from "react";
import SearchBar from "../components/layout/terminal-layout/search-bar";
import TagBar from "../components/layout/terminal-layout/categories-section/cats-bar"
import { ecosystemsTags } from "../constsants";
import { ArticleProps,  } from "../types/types";
import SearchResults from "../components/layout/terminal-layout/search-results";
import ResearchResults from "../components/layout/terminal-layout/research-results";
import MobileSwitch from "../components/layout/terminal-layout/mobile-switch";

const Ecosystems = () => {
  const [searchInput, setSearchInput] = useState("");
  const [activeCategory, setActiveCategory] = useState("All");
    const [sortByClicks, setSortByClicks] = useState(false);

  let filterParams: ArticleProps = {
    types: "Ecosystems",
    pageSize: 10,
    sortedByClicks: sortByClicks,
  };


  return (
    <div className=" flex flex-col min-h-[650px] h-full">
      <SearchBar
        type="Ecosystems"
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        sortByClicks={sortByClicks}
        setSortByClicks={setSortByClicks}
      />
      <MobileSwitch />

      {searchInput.length > 0 ? (
        <SearchResults
          searchQuery={searchInput}
          pageSize={10}
          type="Ecosystems"
        />
      ) : (
        <ResearchResults filterParams={filterParams} />
      )}
    </div>
  );
};

export default Ecosystems;
